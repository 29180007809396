<template>
    <div v-for="trackerGroup in trackerGroups">
        <i class="material-icons-outlined alert-icon" @click="editAlerts(trackerGroup)">notification_important</i>
        <SensorGauges :deviceOffline="false" 
        :loaded="true" :data="trackerGroup.DonutData" :doughnutOptions="trackerGroup.DonutOptions">
    </SensorGauges>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue'
import { ITrackerData } from '../models/tracker-data.js';
import { TrackerGroup } from '../models/tracker-group.js';
import SensorGauges from "./SensorGauges.vue";

const trackerGroups = ref<TrackerGroup[]>([]); // trackerGroups

const props = defineProps({
    feature: { type: Number, required: true },
    trackerTraces: { type: Array<ITrackerData>, required: true },
    editAlerts: { type: Function, required: true },
    device: { type: Object }
})

watch(() => props.feature,
    async (newFeature: number, oldFeature: number) => {
        await initializeTrackerGroups(props.device);
    }
);

watch(() => props.trackerTraces,
    async (newTrackerTraces: Array<ITrackerData>, oldTrackerTraces: Array<ITrackerData>) => {
        await initializeTrackerGroups(props.device);
    }
);

async function initializeTrackerGroups(device: any) {
    if (props.feature === 0 || props.trackerTraces === undefined || props.trackerTraces.length === 0) {
        return;
    }

    if (props.feature !== 0) {
        if (trackerGroups.value.length === 0) {
            trackerGroups.value.push(new TrackerGroup(props.trackerTraces, props.feature, props.device));
        }
        else {
            trackerGroups.value.forEach((trackerGroup: TrackerGroup) => {
                trackerGroup.refresh(device);
            });
        }
    }
}
</script>

<style scoped>
.subtxt {
    display: inline-block;
    color: #ffffff;
    padding: 5px;
    margin-bottom: 0;
    font-size: 0.8rem;
}
</style>