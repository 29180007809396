import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
import { useSlotStore } from "../stores/slotStore";
import { ITrackerData } from '../models/tracker-data';

dayjs.extend(utc);
dayjs.extend(tz);

// Return slot Summaries as well as populating latArray and longArray 
export async function fromSlotsToSummaries(isArt, allSlots, latArray, longArray) {
    let slotSummaries = [];
    const endDate = Date.now();
    let minTimeHeard = import.meta.env.VITE_APP_HoursOffline * 3600 * 1000;
    let minDay = new Date(endDate - minTimeHeard);
    await Promise.all(allSlots.map(async (slotEntry, dev) => {
        let name = slotEntry.name;
        let slotId = slotEntry._id;

        let lstTime = false;
        lstTime = new Date(slotEntry.device_last_publish * 1000) >= minDay;

        var slotData = {
            name: name,
            account_name: slotEntry.account_name,
            version: null,
            id: slotId,
            online: false,
            flag: 0,
            lastHeard: "",
            bat: null,
            sig: null,
            lastPC: null,
            pcAlert: null,
            lastCA: null,
            caAlert: null,
            lastTB: null,
            lastTEMP: null,
            tbAlert: null,
            inWater: slotEntry.inWater,
            enabled: slotEntry.enabled,
            latLong: {
                lat: parseFloat(slotEntry.gpsLat, 10),
                lng: parseFloat(slotEntry.gpsLong, 10)
            },
            feature: slotEntry.feature
        };
        if (!lstTime) { // no data returned
            latArray[dev] = parseFloat(slotEntry.gpsLat, 10);
            longArray[dev] = parseFloat(slotEntry.gpsLong, 10);
            if (slotEntry.device_last_publish) {
                slotData.lastHeard = dayjs.utc(slotEntry.device_last_publish * 1000).local().format('MM-DD HH:mm:ss a')
            } else { slotData.lastHeard = "Unknown"; }
        }
        else {
            let setFlag = 0;
            var gpsLat = parseFloat(slotEntry.gpsLat, 10);
            var gpsLong = parseFloat(slotEntry.gpsLong, 10);
            // console.log(slotData.name + " : " + getReportsResult.data.report.deviceProps.length);
            if (slotEntry.values !== null) {
                //const reportResult = getReportsResult.data.report;
                const datSlot = slotEntry.values;
                // create alerts
                let tpcAlert = slotEntry.deviceAlerts.find((x) => x.devicePropName == "phycocyanin").high;
                let tcaAlert = slotEntry.deviceAlerts.find((x) => x.devicePropName == "chlorA").high;
                let ttbAlert = slotEntry.deviceAlerts.find((x) => x.devicePropName == "turbidity").high;
                // set flag if error
                let bVal = datSlot.find((x) => x.name == "battSOC").value.toFixed(0);
                let pcVal = datSlot.find((x) => x.name == "phycocyanin").value.toFixed(0);
                let caVal = datSlot.find((x) => x.name == "chlorA").value.toFixed(0);
                let tuVal = datSlot.find((x) => x.name == "turbidity").value.toFixed(0);
                let tpVal = datSlot.find((x) => x.name == "waterTemp").value.toFixed(0);
                //let tpValF = 1.8(datSlot.find((x) => x.name == "airTemp").value.toFixed(0))+32;
                if (bVal <= 60 || pcVal >= tpcAlert || caVal >= tcaAlert || tuVal >= ttbAlert) { // red alert
                    setFlag = 2;
                }
                else if (bVal < 70 || pcVal >= (tpcAlert - tpcAlert * .2)
                    || caVal >= (tcaAlert - tcaAlert * .2) || tuVal >= (ttbAlert - ttbAlert * .2)) { // yellow
                    setFlag = 1;
                }

                // gps data
                gpsLat = slotEntry.gpsLat;
                gpsLong = slotEntry.gpsLong;
                //update slotData
                slotData.online = slotEntry.enabled || isArt;
                slotData.flag = setFlag;
                slotData.lastHeard = dayjs.utc(slotEntry.device_last_publish * 1000).local().format('MM-DD HH:mm:ss a');
                slotData.bat = bVal;
                slotData.version = datSlot.find((x) => x.name == "codeVersion").value;
                slotData.sig = datSlot.find((x) => x.name == "sigStrength").value.toFixed(0);
                slotData.lastPC = pcVal;
                slotData.pcAlert = tpcAlert;
                slotData.lastCA = caVal;
                slotData.caAlert = tcaAlert;
                slotData.lastTB = tuVal;
                slotData.lastTEMP = tpVal;
                slotData.tbAlert = ttbAlert;
                slotData.inWater = slotEntry.inWater;
                slotData.enabled = slotEntry.enabled;
                slotData.latLong = {
                    lat: gpsLat,
                    lng: gpsLong
                };
                slotData.feature = slotEntry.feature;
            }
            else {
                if (slotEntry.device_last_publish) {
                    slotData.lastHeard = dayjs.utc(slotEntry.device_last_publish * 1000).local().format('MM-DD HH:mm:ss a')
                } else { slotData.lastHeard = "Unknown"; }
            }
            latArray[dev] = gpsLat;
            longArray[dev] = gpsLong;
        }

        // Testing
        slotSummaries.push(slotData);
    }));

    // Sort by organization name
    slotSummaries.sort((a, b) => {
        if (a.account_name > b.account_name) return 1;
        if (a.account_name < b.account_name) return -1;
        if (a.online < b.online) return 1;
        if (a.online > b.online) return -1;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    });

    return slotSummaries;
}

export function removeDuplicates(data) {
    // console.log(data);
    const newArray = data.reduce((acc, current) => {
        const x = acc.find((item) => item[0] === current[0]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
    // console.log(newArray);
    return newArray;
}

export function getDefaultOptions() {
    const optionDefaults = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0,
        },
        elements: {
            point: {
                radius: 2,
            },
        },
        interaction: {
            intersect: false,
            mode: `index`
        },
        plugins: {
            title: {
                text: "",
                display: true,
                color: "#d7d7d8",
                fontSize: 14,
                fontFamily: "Jura",
                fontWeight: "lighter",
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const dataPointDetails =
                            context.dataset.data[context.dataIndex];
                        return dataPointDetails.toFixed(2);
                    },
                },
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    color: 'white',

                    filter: function (item, chart) {
                        if (!item.hidden) {
                            return chart.datasets[item.datasetIndex].label;
                        }
                    },
                },
            },
            annotation: {},
            /*
            zoom: {
                zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.02
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy'
                }
            }
            */
        },
        scales: {
            x_axis_0:
            {
                gridLines: {
                    display: true,
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                    color: "white",
                    fontWeight: "lighter",
                    font: {
                        size: 10
                    },
                    maxRotation: 25,
                    minRotation: 25
                }
            },
            y_axis_1:
            {
                gridLines: {
                    display: true,
                },
                display: true,
                position: "left",
                scaleLabel: {
                    display: true,
                    labelString: "",
                    color: "white",
                    fontFamily: "Jura",
                    fontWeight: "lighter",
                },
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    stepSize: 10,
                    color: "white",
                },
            },
            y_axis_2: {
                display: true,
                gridLines: {
                    display: true,
                },
                position: "right",
                scaleLabel: {
                    display: true,
                    labelString: "",
                    color: "white",
                    fontFamily: "Jura",
                    fontWeight: "lighter",
                },
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    stepSize: 10,
                    color: "white",
                }
            },
        }
    };
    return optionDefaults;
}

export function getLineChartColor(name) {
    let color = "#fcba03";
    switch (true) {
        case name === "chlorA":
            color = "#008002";
            break;
        case name === "phycocyanin":
            color = "#0072FF";
            break;
        case name === "algaeRatio":
            color = "#ffffff";
            break;
        case name === "dayAvgPC":
            color = "#C7E0FF";
            break;
        case name === "dayAvgCA":
            color = "#BFD6C0";
            break;
        case name === "dayAvgTU":
            color = "#a5111a";
            break;
        case name === "airTemp":
            color = "#3FEA49";
            break;
        case name === "waterTemp":
            color = "#3fe1ea";
            break;
        case name === "ph":
            color = "#00FF00";
            break;
        case name === "do":
            color = "#0000FF";
            break;
        case name === "ps":
            color = "#00FF00";
            break;
        case name === "light":
            color = "#FFC300";
            break;
        case name === "turbidity":
            color = "#f5554a";
            break;
        case name === "battSOC":
            color = "#972262";
            break;
        case name === "sigStrength":
            color = "#d11141";
            break;
        case name === "panelVolts":
            color = "#ffee00";
            break;
        case name === "leakDetect":
            color = "#bc051f";
            break;
        case name === "pcTrend":
            color = "#3C93FF";
            break;
        case name === "caTrend":
            color = "#40A041";
            break;
        case name === "wtTrend":
            color = "#3fe1ea";
            break;
        case name === "tuTrend":
            color = "#ff8876";
            break;
        case name === "pcFieldCal":
            color = "#60A7FF";
            break;
        case name === "pcTrendFieldCal":
            color = "#9FCAFF";
            break;
        case name === "caFieldCal":
            color = "#60B061";
            break;
        case name === "caTrendFieldCal":
            color = "#9FCFA0";
            break;
        case name === "pcNPQ":
            color = "#4095FF";
            break;
        case name === "pcTempComp":
            color = "#80B9FF";
            break;
        case name === "caNPQ":
            color = "#80C081";
            break;
        case name === "tuTempComp":
            color = "#f28389";
            break;
        case name === "precipitationIntensity":
            color = "#00bfff";
            break;
        case name === "windSpeed":
            color = "#ffff00";
            break;
        default:
            break;
    }
    return color;
}

export function getDataset(deviceHistory, sensorName, yAxisID) {
    const sensorIndex = deviceHistory.sensors.findIndex((x) => x.sensorName === sensorName);
    const sensor = deviceHistory.sensors[sensorIndex];
    const dataset = {
        data: sensor.history.map((item) => item.val).reverse(),
        fill: true,
        label: sensor.sensorName,
        backgroundColor: getLineChartColor(sensor.sensorName),
        borderColor: getLineChartColor(sensor.sensorName),
        borderWidth: 1,
        yAxisID: yAxisID,
        hidden: false
    };
    return dataset;
}

function invertColor(hexTripletColor) {
    var color = hexTripletColor;
    color = color.substring(1); // remove #
    color = parseInt(color, 16); // convert to integer
    color = 0xFFFFFF ^ color; // invert three bytes
    color = color.toString(16); // convert to hex
    color = ("000000" + color).slice(-6); // pad with leading zeros
    color = "#" + color; // prepend #
    return color;
}

export function getCompensatedDataset(sensorsSelected, deviceHistory, sensorName, yAxisID, compSensorName) {
    const sensorIndex = deviceHistory.sensors.findIndex((x) => x.sensorName === sensorName);
    const compSensorIndex = deviceHistory.sensors.findIndex((x) => x.sensorName === compSensorName);
    const sensor = deviceHistory.sensors[sensorIndex];
    const compSensor = deviceHistory.sensors[compSensorIndex];
    // Compute compensated values
    const compensatedResults = [];
    for (let index = 0; index < sensor.history.length; index++) {
        const sensorValue = sensor.history[index].val;
        const compSensorValue = compSensor.history[index].val;
        // Get comp settings
        const setting = sensorsSelected.find((item) => item.sensor === sensorName);
        const k1 = parseFloat(setting.k1);
        const k2 = parseFloat(setting.k2);
        const c1 = parseFloat(setting.c1);
        const c2 = parseFloat(setting.c2);
        const t1 = parseFloat(setting.t1);
        const k = (k2 - k1) * (sensorValue - c1) / (c2 - c1) + k1;
        let compValue = sensorValue - k * (compSensorValue - t1);
        if (compValue < 0) {
            compValue = 0;
        }

        compensatedResults.push(compValue);
    }

    const dataset = {
        data: compensatedResults.reverse(),
        fill: true,
        label: sensor.sensorName + "-comp",
        backgroundColor: invertColor(getLineChartColor(sensor.sensorName)),
        borderColor: invertColor(getLineChartColor(sensor.sensorName)),
        borderWidth: 1,
        yAxisID: yAxisID,
        hidden: false
    };

    return dataset;
}

// Return : {lat: xxx, long: yyy}
export async function getSnapshotGps(slotId) {
    const slotStore = useSlotStore();
    await slotStore.fetchSlot({ id: slotId });
    const device = slotStore.currentSlot;
    let lat = device.gpsLat;
    let long = device.gpsLong;
    await slotStore.fetchDeviceSnapshot();
    if (device.useDefaultGPS === false) {
        const deviceSnapshot = slotStore.deviceSnapshot;
        const latCandidate = deviceSnapshot.deviceProps.find((prop)=>prop.name === 'latitude');
        const longCandidate = deviceSnapshot.deviceProps.find((prop)=>prop.name === 'longitude');  
        if (latCandidate !== undefined && longCandidate !== undefined) {
            const latSnapshot = latCandidate.result;
            const longSnapshot = longCandidate.result;
            if (latSnapshot !== 1 && longSnapshot !== 1 && latSnapshot !== 0 && longSnapshot !== 0) {
                lat = latSnapshot;
                long = longSnapshot;
            }
        }
    }

    return {lat, long};
}

export function getReportMinMax(reports, lower, upper) {
    // lower bound == maximum min val plot will display (may be <)
    // upper bound == minium max plot will display (may be  > )
    // i.e. if sensor min == 4, lower == 0 display 0
    // i.e. if sensor max == 12, upper == 20 display 20
    // const reports = timeReports[index];
    let max = Math.max(...reports);
    let min = Math.min(...reports);
    if (min === max) {
        max = 1.5 * min;
    }
    if (lower !== null) {
        if (lower < min) {
            min = lower;
        }
    }

    if (upper !== null) {
        if (max < upper) {
            max = upper;
        }
    }
    return [min, max];
}

export function getAxesMinMaxStep(min, max, numTicks, minZero) {
    //minZero indicates overwrite y min axes to 0 (not negative), 1 true 0 false
    var axMax = Math.ceil(max + Math.abs(max * 0.15));
    var axMin = Math.floor(min - Math.abs(min * 0.15));
    if (minZero == 1 && axMin < 0) {
        axMin = 0;
    }
    var range = axMax - axMin;
    var step = Math.round(range / numTicks);
    if (step === 0) {
        return [axMin, axMax, range / numTicks];
    }
    else {
        axMax = Math.round(axMin + numTicks * step);
        return [axMin, axMax, step];
    }
}

export function featureEnabled(slotSummary, feature) {
    const slotFeature = slotSummary.feature;
    if(slotFeature === 0) {// AT
        const algaeTrackerOnlySensors = ['ps', 'do', 'ph'];
        if (algaeTrackerOnlySensors.includes(feature)) {
            return false;
        }
    }
    if(slotFeature === 1) {// Turbidity
        const algaeTrackerOnlySensors = ['phycocyanin', 'chlorA', 'pc', 'ca', 'phycocyaninTrend', 'chlorATrend', 'algaeRatio', 'ph', 'ps', 'do'];
        if (algaeTrackerOnlySensors.includes(feature)) {
            return false;
        }
    }
    else if (slotFeature === 2) // MT-DO
    {
        const algaeTrackerOnlySensors = ['ph'];
        if (algaeTrackerOnlySensors.includes(feature)) {
            return false;
        }
    }
    else if (slotFeature === 3) // MT-PH
    {
        const algaeTrackerOnlySensors = ['do', 'ps'];
        if (algaeTrackerOnlySensors.includes(feature)) {
            return false;
        }
    }

    return true;
}