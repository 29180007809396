<template>
    <div v-for="trackerGroup in trackerGroups" class="col-12 cust-col">
        <div class="card chart">
            <LineChart :chartdata="trackerGroup.PlotData" :options="trackerGroup.PlotOptions" :plugins="[LinePlugin]"
                style="height:300px;" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue'
import LinePlugin from "../plugins/LinePlugin";
import LineChart from "./LineChart.vue";
import { ITrackerData } from '../models/tracker-data';
import { TrackerGroup } from '../models/tracker-group.js';

const trackerGroups = ref<TrackerGroup[]>([]); // trackerGroups

const props = defineProps({
    feature: { type: Number, required: true },
    trackerTraces: { type: Array<ITrackerData>, required: true },
    device: { type: Object }
})

watch(() => props.feature,
    async (newFeature: number, oldFeature: number) => {
        await initializeTrackerGroups();
    }
);

watch(() => props.trackerTraces,
    async (newTrackerTraces: Array<ITrackerData>, oldTrackerTraces: Array<ITrackerData>) => {
        await initializeTrackerGroups();
    }
);

async function initializeTrackerGroups() {
    trackerGroups.value = [new TrackerGroup(props.trackerTraces, props.feature, props.device)];
}
</script>

<style scoped>
.subtxt {
    display: inline-block;
    color: #ffffff;
    padding: 5px;
    margin-bottom: 0;
    font-size: 0.8rem;
}
</style>