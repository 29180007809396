import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(tz);

class SensorEntry {
    val: number;
    name: string;
    handshakeTime: string;
    timestamp: Date;
    interpolated: boolean;
}


class SensorSnapshot {
    sensorName: string = "";
    value: number = 0;
}

// For a particular timestamp, populate the corresponding sensor values
class SensorsSnapshot {
    timestamp: number = 0;
    sensors: Array<SensorSnapshot> = [];

    exportHeader() {
        let timeString: string = 'timeString';
        const sensorNames: Array<string> = [timeString];
        this.sensors.forEach((sensor) => {
            sensorNames.push(sensor.sensorName);
        });

        const header = sensorNames.join(",");
        return "," + header; // To be consistent with sensor values
    }

    exportSensorValues(deviceZone: any) {
        let timeStringValue: string = '';
        if (deviceZone.status === "OK") {
            timeStringValue = dayjs.tz(this.timestamp * 1000, deviceZone.timeZoneId).format("MM/DD/YY HH:mm:ss");
        } else {
            timeStringValue = dayjs(this.timestamp * 1000).format("MM/DD/YY HH:mm:ss");
        }
        const sensorValues: Array<string> = [timeStringValue.toString()];
        this.sensors.forEach((sensor) => {
            sensorValues.push(sensor.value.toString());
        });

        const values = sensorValues.join(",");
        return values;
    }
}

export class ExportUtility {
    // sensorNames is a filter. We will only export the sensors that are in this list.
    // Return array of lines
    public static exportToCsv(slotHistory: Array<SensorEntry>, sensorNames: Array<string>, deviceZone: any, trendLineProperties: any): Array<string> {
        // Group eensors by name
        const sensorMap = new Map<string, Array<SensorEntry>>();
        slotHistory.forEach((entry: SensorEntry) => {
            if (!sensorMap.has(entry.name)) {
                sensorMap.set(entry.name, []);
            }
            sensorMap.get(entry.name).push(entry);
        });

        // Sort each group by timestamp
        sensorMap.forEach((entries: Array<SensorEntry>) => {
            entries.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
        });

        const utcTimeEntries = sensorMap.get('utcTime');
        const utcTimeTimestamps = utcTimeEntries.map(entry => entry.timestamp);
        // Function to find the closest entry based on timestamp
        const findClosestEntry = (entries: SensorEntry[], timestamp: Date): SensorEntry => {
            let closest = entries[0];
            let closestDiff = Math.abs(entries[0].timestamp.getTime() - timestamp.getTime());
            for (let entry of entries) {
                let diff = Math.abs(entry.timestamp.getTime() - timestamp.getTime());
                if (diff < closestDiff) {
                    closest = entry;
                    closestDiff = diff;
                }
            }
            return closest;
        };

        // Function to ensure each sensor has entries for all utcTime timestamps
        const ensureMatchingTimestamps = (entries: SensorEntry[], timestamps: Date[]): SensorEntry[] => {
            const result: SensorEntry[] = [];
            for (let timestamp of timestamps) {
                const matchingEntry = entries.find(entry => entry.timestamp.getTime() === timestamp.getTime());
                if (matchingEntry) {
                    result.push(matchingEntry);
                } else {
                    const closestEntry = findClosestEntry(entries, timestamp);
                    closestEntry.interpolated = true;
                    result.push({ ...closestEntry, timestamp });
                }
            }
            return result;
        };

        // Clean up each sensor to match the "utcTime" timestamps
        sensorMap.forEach((entries, sensorName) => {
            if (sensorName !== 'utcTime') {
                const updatedEntries = ensureMatchingTimestamps(entries, utcTimeTimestamps);
                sensorMap.set(sensorName, updatedEntries);
            }
        });

        ExportUtility.populateTrendValues(sensorMap, 'phycocyanin', 'phycocyaninTrend', trendLineProperties.pcProperties.kl, trendLineProperties.pcProperties.maxLight, trendLineProperties.pcProperties.alpha);
        ExportUtility.populateTrendValues(sensorMap, 'chlorA', 'chlorATrend', trendLineProperties.caProperties.kl, trendLineProperties.caProperties.maxLight, trendLineProperties.caProperties.alpha);
        ExportUtility.populateTrendValues(sensorMap, 'turbidity', 'turbidityTrend', trendLineProperties.tuProperties.kl, trendLineProperties.tuProperties.maxLight, trendLineProperties.tuProperties.alpha);
  

        const str: Array<string> = [];
        const header = ExportUtility.exportHeader(sensorNames);
        str.push(header);
        str.push("\n");

        utcTimeTimestamps.forEach((entry, index) => {
            const sensorValues: Array<string> = [];
            sensorNames.forEach((sensorName) => {
                const entries = sensorMap.get(sensorName) as Array<SensorEntry>;
                if (sensorName === 'timeString') {
                    sensorValues.push(dayjs(entry).format("MM/DD/YY HH:mm:ss"));
                }
                else {
                    if (entries === undefined || entries[index] === undefined || entries[index].val === undefined) {
                        sensorValues.push('0');
                    }
                    else {
                        const sensorValue = entries[index].val;
                        sensorValues.push(sensorValue.toString());
                    }
                }
            });
    
            const values = sensorValues.join(",");
            str.push(values);
            str.push("\n");
        });

        return str;
    }

    public static lowpassFilter(sensorValues: Array<SensorEntry>, alpha: number): void {
        let outDat: Array<number> = new Array<number>(sensorValues.length);
        for (let i = 0; i < sensorValues.length; i++) {
            if (i == 0) {
                outDat[i] = sensorValues[i].val;
            } else {
                outDat[i] = (1 - alpha) * sensorValues[i].val + alpha * outDat[i - 1];
            }
        }

        for (let i = 0; i < sensorValues.length; i++) {
            sensorValues[i].val = outDat[i];
        }
    }

    public static populateTrendValues(sensorMap: Map<string, Array<SensorEntry>>, rawName: string, trendName: string, k: number, maxLight: number, alpha: number) {
        if (maxLight === undefined) {
            maxLight =  Number.MAX_VALUE;
        }

        const lightEntries = sensorMap.get('light') as Array<SensorEntry>;
        const rawEntries = sensorMap.get(rawName) as Array<SensorEntry>;;
        const sampleSize = lightEntries.length;
        const trendSensorEntries: Array<SensorEntry> = [];
        for(let i = 0; i < sampleSize; i++) {
            const light = lightEntries[i].val;
            const raw = rawEntries[i].val;
            let trendValue = 0;
            if (i === 0) {
                trendValue = raw + k * Math.min(light, maxLight);  
            }
            else {
                const lightPrevious = lightEntries[i-1].val;
                trendValue = raw  + k * Math.min(lightPrevious, maxLight);
            }

            const sensorEntry: SensorEntry = { val: trendValue, handshakeTime: '', name: trendName, timestamp: lightEntries[i].timestamp, interpolated: true };
            trendSensorEntries.push(sensorEntry);
        }

        // Now let's do lower pass filter
        ExportUtility.lowpassFilter(trendSensorEntries, alpha);
        sensorMap.set(trendName, trendSensorEntries);
    }

    public static exportHeader(sensorNames: Array<string>): string{
        const header = sensorNames.join(",");
        return "," + header; // To be consistent with sensor values
    }
}