export function getImage(eventType) {
    const img = new Image();
    if (eventType === 'Rain') {
        img.src = 'https://cdnjs.cloudflare.com/ajax/libs/fontisto/3.0.4/icons/weather/rain.png';
        return img;
    }
    else if (eventType === 'Treatment'){
        img.src = 'https://cdnjs.cloudflare.com/ajax/libs/fontisto/3.0.4/icons/interfaces/male.png';
        return img;
    }
    else {
        return undefined;
    }
  }