<template>
    <div class="home">
        <Header></Header>
        <div :key="componentKey">
            <!-- device info row -->
            <div class="row defaultRow stickyTop">
                <div class="card" style="width: 100%; text-align:center; background-color:#064ea1">
                    <div>
                        <b-dropdown id="dropdown-1" :text="slotName">
                            <b-dropdown-item class="btn" style="width:300px;" v-for="accountDevice in this.outDat"
                                v-bind:key="accountDevice.id" @click="(e) => setDeviceId(accountDevice.id)"
                                :value="accountDevice.id">
                                <img v-if="accountDevice.online" src="/assets/greenCircle.png" class="deviceActiveImg" />
                                <img v-else src="/assets/greyCircle.png" class="deviceActiveImg" />
                                {{ accountDevice.name }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <!-- device status  -->
            <div class="row defaultRow">
                <!-- <div v-if="deviceOffline" class="card overlay">
            <p> Device Offline </p>
            <p> select wider date range or check back later </p>
          </div> -->
                <div class="card" style="width: 100%">
                    <div class="row" style="align-content:center;">
                        <!-- device last heard -->
                        <div class="col-12 col-sm-3" style="text-align:center;">
                            <p class="statusLabel">Device Last Online:</p>
                            <p class="statusLabel">{{ this.lastHeard }}</p>
                        </div>
                        <!-- Signal Strength -->
                        <div class="col-12 col-sm-3">
                            <div style="display: flex; justify-content:center">
                                <p class="statusLabel">Signal Strength:</p>
                                <span @mouseover="sigHover = true" @mouseleave="sigHover = false"
                                    style="display:flex; align-items:center;">
                                    <img class="sigStatImg" v-if="parseInt(signal) > 30" src="/assets/100_sig.png" />
                                    <img class="sigStatImg" v-if="parseInt(signal) <= 30 && parseInt(signal) > 20"
                                        src="/assets/75_sig.png" />
                                    <img class="sigStatImg" v-if="parseInt(signal) <= 20 && parseInt(signal) > 10"
                                        src="/assets/50_sig.png" />
                                    <img class="sigStatImg" v-if="parseInt(signal) <= 10 && parseInt(signal) > 0"
                                        src="/assets/20_sig.png" />
                                    <img class="sigStatImg" v-if="parseInt(signal) <= 0" src="/assets/0_sig.png" />
                                    <span v-if="sigHover" class="statusVal">{{ parseInt(signal).toFixed(0) }}%</span>
                                </span>
                            </div>
                        </div>
                        <!-- battery -->
                        <div class="col-12 col-sm-3">
                            <div style="display: flex; justify-content:center">
                                <p class="statusLabel">Battery Status:</p>
                                <span @mouseover="batHover = true" @mouseleave="batHover = false"
                                    style="display:flex; align-items:center; margin-top:5px">
                                    <img class="battStatImg" v-if="parseInt(battery) > 80" src="/assets/100_batt.png" />
                                    <img class="battStatImg" v-if="parseInt(battery) <= 80 && parseInt(battery) > 60"
                                        src="/assets/80_batt.png" />
                                    <img class="battStatImg" v-if="parseInt(battery) <= 60 && parseInt(battery) > 40"
                                        src="/assets/60_batt.png" />
                                    <img class="battStatImg" v-if="parseInt(battery) <= 40 && parseInt(battery) > 20"
                                        src="/assets/40_batt.png" />
                                    <img class="battStatImg" v-if="parseInt(battery) <= 20 && parseInt(battery) > 0"
                                        src="/assets/20_batt.png" />
                                    <img class="battStatImg" v-if="parseInt(battery) == 0" src="/assets/0_batt.png" />

                                    <span v-if="batHover" class="statusVal">{{ parseInt(battery).toFixed(0) }}%</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3">
                            <div style="display: flex; justify-content:center">
                                <p class="statusLabel">In Water:</p>
                                <div style="margin-left: 8px; margin-top: 6px;">
                                    <div class="pretty p-default p-round">
                                        <input type="checkbox" v-model="deviceInWaterProperty" />
                                        <div class="state p-success-o">
                                            <label>Success</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- weather and map row -->
            <div class="row defaultRow">
                <div class="col-12 col-md-6 cust-col" v-if="inLongLat">
                    <SlotMap :deviceLat="deviceLat" :deviceLong="deviceLong" :gpsLastUpdate="gpsLastUpdate"
                        :mapOptions="mapOptions" :slotName="slotName">
                    </SlotMap>
                </div>
                <div class="col-12 col-md-6 cust-col">
                    <div class="card" id="weatherBox" style="position: relative;">
                        <WeatherWidget :lat="deviceLat.toString()" :long="deviceLong.toString()" :metric="deg">
                        </WeatherWidget>
                    </div>
                </div>
            </div>

            <!-- on load -->
            <loading :active="isLoading" :can-cancel="true" :is-full-page="true"></loading>

            <!-- row 2/3 - Sensor Gauges-->
            <div class="align-content-center justify-content-center row defaultRow">
                <!--phycocyanin-->
                <div class="col-6 col-md-4" id="doughnut" v-if="featureEnabled('phycocyanin')">
                    <div class="card" id="doughnutBox">
                        <i class="material-icons-outlined alert-icon"
                            @click="editAlerts(statCharts[4])">notification_important</i>
                        <SensorGauges :deviceOffline="deviceOffline" :data="statCharts[4]"
                            :doughnutOptions="doughnutOptions" :loaded="loaded" :key="keyPCStatChart">
                        </SensorGauges>
                    </div>
                </div>
                <!--chlorophyll-->
                <div class="col-6 col-md-4" id="doughnut" v-if="featureEnabled('chlorA')">
                    <div class="card">
                        <i class="material-icons-outlined alert-icon"
                            @click="editAlerts(statCharts[5])">notification_important</i>
                        <SensorGauges :deviceOffline="deviceOffline" :data="statCharts[5]"
                            :doughnutOptions="doughnutOptions" :loaded="loaded" :key="keyCAStatChart">
                        </SensorGauges>
                    </div>
                </div>
                <!--turbidity -->
                <div class="col-6 col-md-4" id="doughnut">
                    <div class="card">
                        <i class="material-icons-outlined alert-icon"
                            @click="editAlerts(statCharts[3])">notification_important</i>
                        <SensorGauges :deviceOffline="deviceOffline" :data="statCharts[3]"
                            :doughnutOptions="doughnutOptions" :loaded="loaded" :key="keyTUStatChart">
                        </SensorGauges>
                    </div>
                </div>
                <!--air temp-->
                <div class="col-6 col-md-4" id="doughnut" v-if="device?.feature === 0" >
                    <div class="card">
                        <i class="material-icons-outlined alert-icon"
                            @click="editAlerts(statCharts[1])">notification_important</i>
                        <SensorGauges :deviceOffline="deviceOffline" :data="statCharts[1]"
                            :doughnutOptions="doughnutOptions" :loaded="loaded" :key="keyATStatChart">
                        </SensorGauges>
                    </div>
                </div>
                <div class="col-6 col-md-4" id="doughnut" v-if="device?.feature !== 0" >
                    <div class="card">
                        <TrackerTracesGauges :feature="device?.feature" :trackerTraces="newDataArray" :editAlerts="editAlerts" :device="device"></TrackerTracesGauges>
                    </div>
                </div>
                
                <!--water-->
                <div class="col-6 col-md-4" id="doughnut">
                    <div class="card " id="doughnutBox">
                        <i class="material-icons-outlined alert-icon"
                            @click="editAlerts(statCharts[2])">notification_important</i>
                        <SensorGauges :deviceOffline="deviceOffline" :data="statCharts[2]"
                            :doughnutOptions="doughnutOptions" :loaded="loaded" :key="keyWTStatChart">
                        </SensorGauges>
                    </div>
                </div>
                <!--solar-->
                <div class="col-6 col-md-4" id="doughnut">
                    <div class="card">
                        <i class="material-icons-outlined alert-icon"
                            @click="editAlerts(statCharts[0])">notification_important</i>
                        <SensorGauges :deviceOffline="deviceOffline" :data="statCharts[0]"
                            :doughnutOptions="doughnutOptions" :loaded="loaded" :key="keyLIStatChart">
                        </SensorGauges>
                    </div>
                </div>
            </div>
            <!-- Utility Bar -->
            <div class="row defaultRow">
                <div class="card" style="width: 100%">
                    <p class="util-text">Options</p>
                    <div class="util-opts">
                        <BootstrapIcon icon="calendar2-plus" class="util-tools" @click="eventModal = !eventModal"
                            v-b-popover.hover.top="'add weather/treatment event'"></BootstrapIcon>
                        <BootstrapIcon v-if="!isViewerOnly" icon="bullseye" class="util-tools"
                            @click="fieldCalModal = !fieldCalModal" v-b-popover.hover.top="'calibrate device'">
                        </BootstrapIcon>
                        <BootstrapIcon v-else icon="bullseye" style="color:#525252" class="util-tools"
                            v-b-popover.hover.top="'contact admin to access'"></BootstrapIcon>
                        <BootstrapIcon icon="arrow-clockwise" @click="refreshUI()" class="util-tools"
                            v-b-popover.hover.top="'refresh data (get latest data from device)'"></BootstrapIcon>
                        <BootstrapIcon icon="calendar3" @click="selectChartDatesModal = !selectChartDatesModal"
                            class="util-tools" v-b-popover.hover.top="'change plot date ranges'"></BootstrapIcon>
                        <BootstrapIcon icon="download" @click="exportModal = !exportModal" class="util-tools"
                            v-b-popover.hover.top="'download data to local computer'"></BootstrapIcon>
                        <BootstrapIcon icon="wrench" @click="chartPropertiesModal = !chartPropertiesModal"
                            class="util-tools" v-b-popover.hover.top="'edit chart properties'"></BootstrapIcon>
                        <BootstrapIcon v-if="this.isART" icon="gear-fill" @click="goToManageDevice" class="util-tools"
                            v-b-popover.hover.top="'advanced settings'"></BootstrapIcon>
                        <BootstrapIcon v-if="this.isART" icon="graph-up" class="util-tools" @click="goCompensated()"
                            v-b-popover.hover.top="'Compensated Plots'"></BootstrapIcon>
                        <BootstrapIcon icon="bucket" class="util-tools" @click="goCalibration()"
                            v-b-popover.hover.top="'Calibration'"></BootstrapIcon>
                        <BootstrapIcon icon="house" @click="goHome()" class="util-tools"
                            v-b-popover.hover.top="'return home'">
                        </BootstrapIcon>
                    </div>
                </div>
            </div>
            <!--phycocyanin and chlorophyll line chart -->
            <div class="align-content-center justify-content-center row defaultRow" v-if="featureEnabled('phycocyanin')">
                <div class="card" style="width: 100%">
                    <line-chart class="lineChart" v-if="algaeTimeReportsChart" :chartdata="algaeTimeReportsChart"
                        :options="algaeLineChartOptions" :key="keyCaPc" :plugins="LinePlugin" />
                    <div class="align-content-center justify-content-center row">
                        <div style="display: flex; flex-direction: row; width: 100%; justify-content: center;">
                            <b-form-checkbox v-model="spikeRemovalFlag" class="util-text">Spike
                                Removal&nbsp;</b-form-checkbox>
                            <b-form-checkbox v-model="rawPlot" class="util-text">Detail</b-form-checkbox>
                            <b-form-checkbox v-model="smoothPlot" class="util-text"
                                style="margin-left: 15px">Trend</b-form-checkbox>
                            <b-form-checkbox v-model="fieldCalPlot" class="util-text" style="margin-left: 15px">Field
                                Calibrated</b-form-checkbox>
                            <b-form-checkbox v-model="showPC" class="util-text"
                                style="margin-left: 15px">PC</b-form-checkbox>
                            <b-form-checkbox v-model="showCA" class="util-text"
                                style="margin-left: 15px">CA</b-form-checkbox>
                            <BootstrapIcon icon="arrow-clockwise" @click="caPcChart(true)" class="util-tools refresh">
                            </BootstrapIcon>
                        </div>
                    </div>
                    <div class="align-content-center justify-content-center " style="text-align: center;"
                        v-if="calPlotError">
                        <p style="margin: auto; padding: auto 3px auto 3px; color:white;">
                            You have selected Field Calibrated but the following sensor(s) have no calibration data:
                        </p>
                        <p style="margin: auto; color:white; padding: auto 3px auto 3px;"> {{ calPlotError }} </p>
                        <p style="margin: auto; color:white; padding: auto 3px auto 3px;">
                            Trend data is displayed instead. </p>
                    </div>
                </div>
            </div>

            <!--water & turbidity :options="waterTempOptions"-->
            <div class="align-content-center justify-content-center row defaultRow">
                <!--water-->
                <div class="col-12 col-md-6 cust-col">
                    <div class="card">
                        <line-chart class="lineChart" :chartdata="waterTempLineChart" :options="waterTempOptions"
                            :key="keyWaterTemp" :plugins="LinePlugin" />
                    </div>
                </div>
                <!-- turbidity -->
                <div class="col-12 col-md-6 cust-col">
                    <div class="card">
                        <line-chart class="lineChart" :chartdata="turbidityLineChart" :options="turbidityOptions"
                            :key="keyTurb" :plugins="LinePlugin" />
                    </div>
                </div>
            </div>
            <!-- Tracker Traces -->
            <div class="align-content-center justify-content-center row defaultRow">
                <TrackerTracesPlots :feature="device?.feature" :trackerTraces="newDataArray" :device="device"></TrackerTracesPlots>
            </div>

            <div class="align-content-center justify-content-center row defaultRow">
                <!--Chen: added time series data-->
                <TimeSeriesPlots :sensorNames="timeSeriesNames" :unitF="unitF"></TimeSeriesPlots>
            </div>

            <!--batt, PV & Signal Strength -->
            <div class="align-content-center justify-content-center row defaultRow">
                <!-- battery and PV -->
                <div class="col-12 col-md-6 cust-col">
                    <div class="card">
                        <line-chart class="lineChart" :chartdata="battPVChart" :options="battPVOptions" :key="keyBatSig"
                            :plugins="LinePlugin" />
                    </div>
                </div>
                <!--Signal Strength and Solar Light-->
                <div class="col-12 col-md-6 cust-col">
                    <div class="card">
                        <line-chart class="lineChart" :chartdata="ssLiChart" :options="sigStrengthSolarOptions"
                            :key="keyLight" :plugins="LinePlugin" />
                    </div>
                </div>
            </div>
            <!-- Utility Bar -->
            <div class="row defaultRow">
                <div class="card" style="width: 100%">
                    <p class="util-text">Options</p>
                    <div class="util-opts">
                        <BootstrapIcon icon="calendar2-plus" class="util-tools" @click="eventModal = !eventModal"
                            v-b-popover.hover.top="'add weather/treatment event'"></BootstrapIcon>
                        <BootstrapIcon v-if="!isViewerOnly" icon="bullseye" class="util-tools"
                            @click="fieldCalModal = !fieldCalModal" v-b-popover.hover.top="'calibrate device'">
                        </BootstrapIcon>
                        <BootstrapIcon v-else icon="bullseye" style="color:#525252" class="util-tools"
                            v-b-popover.hover.top="'contact admin to access'"></BootstrapIcon>
                        <BootstrapIcon icon="arrow-clockwise" @click="refreshUI()" class="util-tools"
                            v-b-popover.hover.top="'refresh data (get latest data from device)'"></BootstrapIcon>
                        <BootstrapIcon icon="calendar3" @click="selectChartDatesModal = !selectChartDatesModal"
                            class="util-tools" v-b-popover.hover.top="'change plot date ranges'"></BootstrapIcon>
                        <BootstrapIcon icon="download" @click="exportModal = !exportModal" class="util-tools"
                            v-b-popover.hover.top="'download data to local computer'"></BootstrapIcon>
                        <BootstrapIcon icon="wrench" @click="chartPropertiesModal = !chartPropertiesModal"
                            class="util-tools" v-b-popover.hover.top="'edit chart properties'"></BootstrapIcon>
                        <BootstrapIcon v-if="this.isART" icon="gear-fill" @click="goToManageDevice" class="util-tools"
                            v-b-popover.hover.top="'advanced settings'"></BootstrapIcon>
                        <BootstrapIcon v-if="this.isART" icon="graph-up" class="util-tools" @click="goCompensated()"
                            v-b-popover.hover.top="'Compensated Plots'"></BootstrapIcon>
                        <BootstrapIcon icon="bucket" class="util-tools" @click="goCalibration()"
                            v-b-popover.hover.top="'Calibration'"></BootstrapIcon>
                        <BootstrapIcon icon="house" @click="goHome()" class="util-tools"
                            v-b-popover.hover.top="'return home'">
                        </BootstrapIcon>
                    </div>
                </div>
            </div>
            <!--custom charts -->
            <div class="align-content-center justify-content-center  defaultRow">
                <div class="card">
                    <line-chart class="lineChart" :chartdata="customChart" :options="customChartOptions" :key="keyCustom"
                        :plugins="LinePlugin" />
                    <div class="align-content-center justify-content-center row" style="height: 40px;">
                        <b-btn class="customChartButton" style="backgroundColor:#005FBE;"
                            @click="createChartModal = !createChartModal">Customize</b-btn>
                    </div>
                </div>
            </div>
            <!--Playground Charts -->
            <div v-if="this.isART && this.showPlaygroundChart"
                class="align-content-center justify-content-center  defaultRow">
                <div class="card" style="width: 100%">
                    <line-chart class="playgroundChart" :chartdata="playgroundChart" :options="playgroundChartOptions"
                        :key="keyPlayground" :plugins="LinePlugin" />
                    <div class="playgroundCard">
                        <!-- update -->
                        <div class="align-content-center justify-content-center row" style="width:100%">
                            <b-btn class="customChartButton"
                                style="margin-bottom:10px; margin-top: 10px; backgroundColor:#005FBE;"
                                @click="createPlayground()">Update Chart</b-btn>
                            <!-- <p class="playgroundHeader"> Update </p>
                <BootstrapIcon icon="arrow-clockwise" @click="updatePlayChart()" class="util-tools refresh"></BootstrapIcon> -->
                        </div>
                        <!-- PC -->
                        <div class="align-content-center justify-content-center row">
                            <div class="col-6" style="border-style:solid; border-color:#717173;">
                                <div class="row">
                                    <p class="playgroundHeader">Phycocyanin</p>
                                </div>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <label class="play-text">left axis</label>
                                    <div class="deg-switch">
                                        <b-form-checkbox switch size="lg" v-model="playPCAx">
                                        </b-form-checkbox>
                                    </div>
                                    <label class="play-text">right axis</label>
                                </div>
                                <div class="row playRow">
                                    <div>
                                        <b-form-checkbox v-model="playPC" class="playgroundText">
                                            Detail</b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playPCTrend" class="playgroundText">Trend
                                        </b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playPCNPQ" class="playgroundText">NPQ
                                        </b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playPCTempComp" class="playgroundText">Temp. Comp.
                                        </b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playPCAvg" class="playgroundText">Day Avg.
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <div class="row playRow">
                                    <div>
                                        <p class="playgroundText">alpha:</p>
                                        <b-form-input class=" playgroundInput" type="number"
                                            v-model.number="trendlineProperties.pcProperties.alpha"
                                            :placeholder="trendlineProperties.pcProperties.alpha.toString()">
                                        </b-form-input>
                                    </div>
                                    <div>
                                        <p class="playgroundText">max light:</p>
                                        <b-form-input class=" playgroundInput" type="number"
                                            v-model.number="trendlineProperties.pcProperties.maxLight"
                                            :placeholder="trendlineProperties.pcProperties.maxLight.toString()">
                                        </b-form-input>
                                    </div>
                                    <div>
                                        <p class="playgroundText">k:</p>
                                        <b-form-input class=" playgroundInput" type="number"
                                            v-model.number="trendlineProperties.pcProperties.kl"
                                            :placeholder="trendlineProperties.pcProperties.kl.toString()">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="row playRow" style="margin-bottom: 10px;">
                                    <div>
                                        <p class="playgroundText">alpha (temp comp)</p>
                                        <b-form-input class="playgroundInput" type="number"
                                            v-model.number="trendlineProperties.pcProperties.alphaTemp"
                                            :placeholder="trendlineProperties.pcProperties.alphaTemp.toString()">
                                        </b-form-input>
                                    </div>
                                    <div>
                                        <p class="playgroundText">Temp during cal. (C):</p>
                                        <b-form-input class="playgroundInput" type="number"
                                            v-model.number="trendlineProperties.pcProperties.stTemp"
                                            :placeholder="trendlineProperties.pcProperties.stTemp.toString()">
                                        </b-form-input>
                                    </div>
                                    <div>
                                        <p class="playgroundText ">k (temp comp):</p>
                                        <b-form-input class="playgroundInput" type="number"
                                            v-model.number="trendlineProperties.pcProperties.kTemp"
                                            :placeholder="trendlineProperties.pcProperties.kTemp.toString()">
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                            <!-- CA -->
                            <div class="col-6" style="border-style:solid; border-color:#717173;">
                                <div class="row">
                                    <p class="playgroundHeader">Chlorophyll-a</p>
                                </div>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <label class="play-text">left axis</label>
                                    <div class="deg-switch">
                                        <b-form-checkbox switch size="lg" v-model="playCAAx">
                                        </b-form-checkbox>
                                    </div>
                                    <label class="play-text">right axis</label>
                                </div>
                                <div class="row playRow">
                                    <div>
                                        <b-form-checkbox v-model="playCA" class="playgroundText">
                                            Detail</b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playCATrend" class="playgroundText">Trend
                                        </b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playCANPQ" class="playgroundText">NPQ
                                        </b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playCAAvg" class="playgroundText">Day Avg.
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <div class="row playRow" style="margin-bottom: 10px;">
                                    <div>
                                        <p class="playgroundText">alpha:</p>
                                        <b-form-input class=" playgroundInput" type="number"
                                            v-model.number="trendlineProperties.caProperties.alpha"
                                            :placeholder="trendlineProperties.caProperties.alpha.toString()">
                                        </b-form-input>
                                    </div>
                                    <div>
                                        <p class="playgroundText">max light:</p>
                                        <b-form-input class=" playgroundInput" type="number"
                                            v-model.number="trendlineProperties.caProperties.maxLight"
                                            :placeholder="trendlineProperties.caProperties.maxLight.toString()">
                                        </b-form-input>
                                    </div>
                                    <div>
                                        <p class="playgroundText">k:</p>
                                        <b-form-input class=" playgroundInput" type="number"
                                            v-model.number="trendlineProperties.caProperties.kl"
                                            :placeholder="trendlineProperties.caProperties.kl.toString()">
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- TB WT -->
                        <div class="align-content-center justify-content-center row">
                            <!-- turbidity -->

                            <div class="col-6 " style="border-style:solid;border-color:#717173">
                                <div class="row">
                                    <p class="playgroundHeader">Turbidity</p>
                                </div>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <label class="play-text">left axis</label>
                                    <div class="deg-switch">
                                        <b-form-checkbox switch size="lg" v-model="playTUAx">
                                        </b-form-checkbox>
                                    </div>
                                    <label class="play-text">right axis</label>
                                </div>
                                <div class="row playRow">
                                    <div>
                                        <b-form-checkbox v-model="playTU" class="playgroundText">
                                            Detail</b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playTUTrend" class="playgroundText"
                                            style="margin-left: 15px">Trend
                                        </b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playTUAvg" class="playgroundText">Day Avg.
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <div class="row playRow">
                                    <div>
                                        <p class="playgroundText">alpha (temp comp):</p>
                                        <b-form-input class="playgroundInput" type="number"
                                            v-model.number="trendlineProperties.tuProperties.alphaTemp"
                                            :placeholder="trendlineProperties.tuProperties.alphaTemp.toString()">
                                        </b-form-input>
                                    </div>
                                    <div>
                                        <p class="playgroundText">temp at cal (C)</p>
                                        <b-form-input class="playgroundInput" type="number"
                                            v-model.number="trendlineProperties.tuProperties.stTemp"
                                            :placeholder="trendlineProperties.tuProperties.stTemp.toString()">
                                        </b-form-input>
                                    </div>
                                    <div>
                                        <p class="playgroundText">k (temp comp)</p>
                                        <b-form-input class="playgroundInput" type="number"
                                            v-model.number="trendlineProperties.tuProperties.kTemp"
                                            :placeholder="trendlineProperties.tuProperties.kTemp.toString()">
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                            <!-- water temp -->
                            <div class="col-6 " style="border-style:solid;border-color:#717173">
                                <div class="row">
                                    <p class="playgroundHeader">Water Temp</p>
                                </div>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <label class="play-text">left axis</label>
                                    <div class="deg-switch">
                                        <b-form-checkbox switch size="lg" v-model="playWTAx">
                                        </b-form-checkbox>
                                    </div>
                                    <label class="play-text">right axis</label>
                                </div>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <label class="play-text">&deg; F</label>
                                    <div class="deg-switch">
                                        <b-form-checkbox switch size="lg" v-model="unitPlayTemp">
                                        </b-form-checkbox>
                                    </div>
                                    <label class="play-text">&deg; C</label>
                                </div>
                                <div class="row playRow">
                                    <div>
                                        <b-form-checkbox v-model="playWT" class="playgroundText">
                                            Detail</b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox v-model="playWTTrend" class="playgroundText"
                                            style="margin-left: 15px">Trend
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <div class="row playRow" style="margin-bottom: 10px;">
                                    <div>
                                        <p class="playgroundText">alpha:</p>
                                        <b-form-input class="playgroundInput" type="number"
                                            v-model.number="trendlineProperties.wtProperties.alpha"
                                            :placeholder="trendlineProperties.wtProperties.alpha.toString()">
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- All other params -->
                        <div class="align-content-center justify-content-center row"
                            style="border-style:solid;border-color:#717173">
                            <div class="row" style="width:100%">
                                <p class="playgroundHeader col-12">Other Parameters</p>
                            </div>

                            <div class="row playRow" style="width:100%">
                                <!-- solar light -->
                                <div>
                                    <b-form-checkbox v-model="playSL" class="playgroundText">Solar Light
                                    </b-form-checkbox>
                                    <div class="d-flex flex-row justify-content-center align-items-center">
                                        <label class="play-text">left</label>
                                        <div class="deg-switch">
                                            <b-form-checkbox switch size="lg" v-model="playSLAx">
                                            </b-form-checkbox>
                                        </div>
                                        <label class="play-text">right</label>
                                    </div>
                                </div>
                                <!-- panel volts -->
                                <div>
                                    <b-form-checkbox v-model="playPV" class="playgroundText">Panel Volts
                                    </b-form-checkbox>
                                    <div class="d-flex flex-row justify-content-center align-items-center">
                                        <label class="play-text">left</label>
                                        <div class="deg-switch">
                                            <b-form-checkbox switch size="lg" v-model="playPVAx">
                                            </b-form-checkbox>
                                        </div>
                                        <label class="play-text">right</label>
                                    </div>
                                </div>
                                <!-- sig strength -->
                                <div>
                                    <b-form-checkbox v-model="playSS" class="playgroundText">Signal Strength
                                    </b-form-checkbox>
                                    <div class="d-flex flex-row justify-content-center align-items-center">
                                        <label class="play-text">left</label>
                                        <div class="deg-switch">
                                            <b-form-checkbox switch size="lg" v-model="playSSAx">
                                            </b-form-checkbox>
                                        </div>
                                        <label class="play-text">right</label>
                                    </div>
                                </div>
                                <!-- bat strength -->
                                <div>
                                    <b-form-checkbox v-model="playBS" class="playgroundText">Battery Strength
                                    </b-form-checkbox>
                                    <div class="d-flex flex-row justify-content-center align-items-center">
                                        <label class="play-text">left</label>
                                        <div class="deg-switch">
                                            <b-form-checkbox switch size="lg" v-model="playBSAx">
                                            </b-form-checkbox>
                                        </div>
                                        <label class="play-text">right</label>
                                    </div>
                                </div>
                                <!-- Leak Detector -->
                                <div>
                                    <b-form-checkbox v-model="playLD" class="playgroundText">Leak Detector
                                    </b-form-checkbox>
                                    <div class="d-flex flex-row justify-content-center align-items-center">
                                        <label class="play-text">left</label>
                                        <div class="deg-switch">
                                            <b-form-checkbox switch size="lg" v-model="playLDAx">
                                            </b-form-checkbox>
                                        </div>
                                        <label class="play-text">right</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- update -->
                        <div class="align-content-center justify-content-center row" style="width:100%">
                            <b-btn class="customChartButton" style="margin-top: 20px; backgroundColor:#005FBE;"
                                @click="createPlayground()">Update Chart</b-btn>

                            <!-- <p class="playgroundHeader"> Update </p>
                <BootstrapIcon icon="arrow-clockwise" @click="updatePlayChart()" class="util-tools refresh"></BootstrapIcon> -->
                        </div>
                        <div class="align-content-center justify-content-center row" style="width:100%">
                            <b-btn class="customChartButton"
                                style="margin-bottom:10px; margin-top: 10px; backgroundColor:#005FBE;"
                                @click="updateTrendLineProperties()">Save Data to Device</b-btn>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Super_Admin settings modal -->
            <b-modal v-if="device" v-model="adminSettingsModal" title="Trendline Properties"
                @ok="updateTrendLineProperties()" ok-title="Update">
                <div>
                    <label class="unitText">{{ awakedMessage }}</label>
                    <input type="checkbox" value="test" v-model="device.wakeRequest" @change="updateAwake($event)" />
                </div>
                <div>
                    <p class="unitText">Show Playground Chart:</p>
                    <input type="checkbox" v-model="showPlaygroundChart" />
                </div>
                <div class="editUnit">
                    <p class="unitText">Trendline alpha:</p>
                    <b-row>
                        <b-col>
                            <p>Phycocyanin</p>
                            <b-form-input type="number" v-model.number="trendlineProperties.pcProperties.alpha"
                                :placeholder="trendlineProperties.pcProperties.alpha.toString()"></b-form-input>
                        </b-col>
                        <b-col>
                            <p>Chlorophyll A</p>
                            <b-form-input type="number" v-model.number="trendlineProperties.caProperties.alpha"
                                :placeholder="trendlineProperties.caProperties.alpha.toString()"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="editUnit">
                    <p class="unitText">Light Gain:</p>
                    <b-row>
                        <b-col>
                            <p>Phycocyanin</p>
                            <b-form-input type="number" v-model.number="trendlineProperties.pcProperties.kl"
                                :placeholder="trendlineProperties.pcProperties.kl.toString()"></b-form-input>
                        </b-col>
                        <b-col>
                            <p>Chlorophyll A</p>
                            <b-form-input type="number" v-model.number="trendlineProperties.caProperties.kl"
                                :placeholder="trendlineProperties.caProperties.kl.toString()"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="editUnit">
                    <p class="unitText">Max Solar Light:</p>
                    <b-row>
                        <b-col>
                            <p>Phycocyanin</p>
                            <b-form-input type="number" v-model.number="trendlineProperties.pcProperties.maxLight"
                                :placeholder="trendlineProperties.pcProperties.maxLight.toString()"></b-form-input>
                        </b-col>
                        <b-col>
                            <p>Chlorophyll A</p>
                            <b-form-input type="number" v-model.number="trendlineProperties.caProperties.maxLight"
                                :placeholder="trendlineProperties.caProperties.maxLight.toString()"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
            <!-- alerts modal -->
            <b-modal v-model="alertEditModal" id="modal-alerts" :title="editChartData.displayName"
                @ok="(e) => saveAlert(e, editChartData, editChartDataRangeValue)">
                <div class="row">
                    <p>Set Sensor Alert Levels</p>
                    <BootstrapIcon icon="question-circle" style="margin-left:0.5rem" id="alert-popover"></BootstrapIcon>
                    <b-popover target="alert-popover" triggers="hover">
                        Use the slider or text boxes below to change alert triggers
                    </b-popover>
                </div>
                <div class="row mt-3" style="margin-bottom:2rem">
                    <div class="col">
                        <vue-slider @change="alertRangeSliderChanged()" ref="alertRangeSlider"
                            v-model="editChartDataRangeValue" :min="alertRangeSliderMin" :max="alertRangeSliderMax"
                            :marks="[alertRangeSliderMin, alertRangeSliderMax]">
                        </vue-slider>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <b-form-input id="alert-low-popover" v-model="editChartDataRangeValue[0]"
                            @change="(val) => setRange(0, val)" placeholder="low"></b-form-input>
                    </div>
                    <div class="col">
                        <b-form-input id="alert-high-popover" v-model="editChartDataRangeValue[1]"
                            @change="(val) => setRange(1, val)" placeholder="high"></b-form-input>
                    </div>
                    <b-popover target="alert-low-popover" triggers="hover">
                        Sensor values below this limit will result in a red gauge
                    </b-popover>
                    <b-popover target="alert-high-popover" triggers="hover" placement="left">
                        Sensor values above this limit will result in a red gauge
                    </b-popover>
                </div>
                <div class="row">
                    <div class="col">
                        Low Limit
                    </div>
                    <div class="col">
                        High Limit
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <b-form-checkbox id="checkbox-alertActive" v-model="editChartData.alertActive"
                            name="checkbox-alertActive">
                            <p style="color: black">Allow Email Notifications</p>
                        </b-form-checkbox>
                    </div>
                </div>
            </b-modal>
            <!-- alert modal for lowest permission level -> 'users' -->
            <b-modal v-model="lowPermAlertEditModal" id="modal-alerts" :title="editChartData.displayName">
                <div class="row">
                    <p>Sensor Alert Level Settings</p>
                    <BootstrapIcon icon="question-circle" style="margin-left:0.5rem" id="alert-popover"></BootstrapIcon>
                    <b-popover target="alert-popover" triggers="hover">
                        Please note you do not have the permissions to change these settings. Please contact
                        your admin or AquaRealTime
                    </b-popover>
                </div>
                <div class="row mt-3" style="margin-bottom:2rem">
                    <div class="col">
                        <vue-slider ref="alertRangeSlider" v-model="editChartDataRangeValue" :min="alertRangeSliderMin"
                            :max="alertRangeSliderMax" :marks="[alertRangeSliderMin, alertRangeSliderMax]" :disabled="true">
                        </vue-slider>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <b-form-input id="alert-low-popover" v-model="editChartDataRangeValue[0]" placeholder="low"
                            :disabled="true"></b-form-input>
                    </div>
                    <div class="col">
                        <b-form-input id="alert-high-popover" v-model="editChartDataRangeValue[1]" placeholder="high"
                            :disabled="true"></b-form-input>
                    </div>
                    <b-popover target="alert-low-popover" triggers="hover">
                        Sensor values below this limit will result in a red gauge
                    </b-popover>
                    <b-popover target="alert-high-popover" triggers="hover" placement="left">
                        Sensor values above this limit will result in a red gauge
                    </b-popover>
                </div>
                <div class="row">
                    <div class="col">
                        Low Limit
                    </div>
                    <div class="col">
                        High Limit
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <b-form-checkbox id="checkbox-alertActive" name="checkbox-alertActive" :disabled="true">Allow
                            Email
                            Notifications</b-form-checkbox>
                    </div>
                </div>
            </b-modal>
            <!-- select date range for charts   -->
            <b-modal v-model="selectChartDatesModal" title="Customize Chart" ok-title="Update" @ok="selectChartDates()">
                <div class="util-sub-head">
                    <p class="util-p">start date:</p>
                    <b-form-input class="exprt-form" type="date" v-model="startDateProperty"></b-form-input>
                </div>
                <div class="util-sub-head">
                    <p class="util-p">end date:</p>
                    <b-form-input class="exprt-form" type="date" v-model="endDateProperty"></b-form-input>
                </div>
            </b-modal>
            <!-- export data -->
            <b-modal v-model="exportModal" title="Export Data" @ok="exportCSV()" ok-title="Export">
                <div class="util-sub-head">
                    <p class="util-p">start date:</p>
                    <b-form-input class="exprt-form" type="date" v-model="startDate"></b-form-input>
                </div>
                <div class="util-sub-head">
                    <p class="util-p">end date:</p>
                    <b-form-input class="exprt-form" type="date" v-model="endDate"></b-form-input>
                </div>
            </b-modal>
            <!-- chart properties modal -->
            <b-modal v-model="chartPropertiesModal" title="Chart Properties" @cancel="chartCancel()" @ok="chartProperties()"
                ok-title="Update">
                <div class="editUnit">
                    <p class="unitText">Temperature:</p>
                    <p class="unitText">&deg; F</p>
                    <b-form-checkbox switch size="lg" v-model="unitF" class="deg-switch"> </b-form-checkbox>
                    <p class="unitText">&deg; C</p>
                </div>
                <div class="editUnit">
                    <p class="unitText">Water Temperature Trend:</p>
                    <p class="unitText">Off</p>
                    <b-form-checkbox switch size="lg" v-model="waterTrend" class="deg-switch">
                    </b-form-checkbox>
                    <p class="unitText">On</p>
                </div>
                <div class="editUnit">
                    <p class="unitText">Turbidity trend:</p>
                    <p class="unitText">Off</p>
                    <b-form-checkbox switch size="lg" v-model="turbidityTrend" class="deg-switch">
                    </b-form-checkbox>
                    <p class="unitText">On</p>
                </div>
                <div v-if="isART" class="editUnit">
                    <p class="unitText">Show Playground Chart:</p>
                    <input type="checkbox" v-model="showPlaygroundChart" />
                </div>
            </b-modal>
            <!-- Create Custom Chart modal -->
            <b-modal v-model="createChartModal" title="Choose Parameters" @cancel="chartCancel()" @ok="createCustomChart()"
                ok-title="Update">
                <div>
                    <b-form-group v-for="(sensor, index) in sensorsSelected" style="margin-bottom: 5px;">
                        <div class="row" v-if="featureEnabled(sensor.feature)">
                            <div class="col-6" style="display: flex;">
                                <b-form-checkbox v-model="sensor.selected">
                                </b-form-checkbox>
                                <p>{{ sensor.text }}</p>
                            </div>
                            <div class="col-6" style="display: flex;">
                                <b-form-select v-model="sensor.axis" :options="axesOpts">
                                </b-form-select>
                            </div>
                        </div>
                    </b-form-group>
                </div>
            </b-modal>
            <!-- calibrate device to field sample -->
            <b-modal v-model="fieldCalModal" title="Calibrate Device" ok-title="Calibrate"
                @ok="applyCal(calSensor, calVal, fieldSample)" ok-variant="success">
                <div>
                    <div>
                        <b-row style="margin-bottom: 5px;">
                            <b-col cols="4">
                                <p class="util-p">Sensor:</p>
                            </b-col>
                            <b-col cols="8">
                                <b-form-select v-model="calSensor" :options="calSensorOptions" class="exprt-form">
                                </b-form-select>
                            </b-col>
                        </b-row>

                        <b-row style="margin-bottom: 5px;">
                            <b-col cols="4">
                                <p class="util-p">Calibration Date:</p>
                            </b-col>
                            <b-col cols="8">
                                <b-form-input class="exprt-form" type="date" v-model="calDate" required></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row style="margin-bottom: 5px;">
                            <b-col cols="4">
                                <p class="util-p">Calibration Time:</p>
                            </b-col>
                            <b-col cols="8">
                                <b-form-input class="exprt-form" type="time" v-model="calTime" required></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <p class="util-p">Field Sample (&#181;g/L):</p>
                            </b-col>
                            <b-col cols="8">
                                <b-form-input class="exprt-form" placeholder="sample ug/L" v-model="fieldSample" required>
                                </b-form-input>
                            </b-col>
                        </b-row>
                    </div>
                    <div style="text-align:center">
                        <b-btn @click="checkCal(calDate, calTime, calSensor, fieldSample)"
                            style="background:#49dcf3; color:black;">
                            Check Calibration
                        </b-btn>
                    </div>
                    <line-chart class="lineChart" v-if="verCalChart" :chartdata="calChartData" :options="calChartOptions"
                        :key="keyCalChart" :plugins="LinePlugin" />
                    <div v-if="verCalChart" style="margin-top:5px;">
                        <p>
                            The closest measurement by the AlgaeTracker is
                            <strong> {{ Math.round(calVal * 10) / 10 }}RFU </strong> one
                            <strong>{{ calDate }}</strong> at <strong>{{ calTime }}</strong> and is shown in red
                            in the plot above.
                        </p>
                        <p>
                            Calibrate <strong> {{ Math.round(calVal * 10) / 10 }} RFU </strong> to
                            <strong> {{ fieldSample }}&#181;g/L </strong>?
                        </p>
                        <!-- <div style="text-align:center">
              <b-btn @click="postCal(calSensor,calVal,fieldSample)"> Calibrate {{calSensor}} </b-btn>
            </div> -->
                    </div>
                    <div v-if="calError">
                        <p>{{ calError }}</p>
                    </div>
                    <div v-if="calSuccess">
                        <p>{{ calSuccess }}</p>
                    </div>
                </div>
            </b-modal>
            <!-- Add Event (weather or treatment) -->
            <b-modal v-model="eventModal" title="" hide-footer>
                <div class="overflow-auto">
                    <h3>Previous Events</h3>

                    <b-table id="event-table" style="max-width: 90%; text-align:center" striped hover bordered small
                        :items="eventsProperty" :fields="eventFields" :sort-compare-options="{ numeric: true }"
                        :sort-by.sync="eventSort" responsive="sm" ref="eventSelect" selectable
                        @row-selected="onEventSelected" :per-page="eventPerPage" :current-page="eventCurrentPage">
                        <template #cell(delete_event)="row">
                            <BootstrapIcon icon="trash" @click="deleteEvent(row)">
                                Show Details
                            </BootstrapIcon>
                        </template>
                    </b-table>
                    <b-pagination v-model="eventCurrentPage" :total-rows="eventRows" :per-page="eventPerPage"
                        aria-controls="event-table"></b-pagination>
                </div>
                <div>
                    <h3>Add New Event</h3>
                    <div class="mt-3">Event Type:</div>
                    <div class="row">
                        <div class="col-9">
                            <b-form-select v-model="newEventSelected" :options="newEventOptions"></b-form-select>
                        </div>
                    </div>

                    <div class="mt-3">Event Date/Time:</div>
                    <div class="row">
                        <div class="col-6">
                            <Datepicker v-model="newEventDateTime"></Datepicker>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px; margin-bottom: 10px;">
                        <b-btn class="customChartButton" style="margin-top: 20px; backgroundColor:#005FBE; cursor: pointer;"
                            @click="addEvent()"> Add Event </b-btn>
                    </div>
                    <div class="row" v-if="this.addEventError">
                        <p style="color: red;">{{ addEventError }}</p>
                    </div>
                </div>
            </b-modal>
            <Footer></Footer>
        </div>
    </div>
</template>
  
<style lang="scss">
// Extra small screen / phone
$screen-xs: 425px !default;
// Small screen / tablet
$screen-sm: 768px !default;
// Medium screen / desktop
$screen-md: 992px !default;
// Large screen / wide desktop
$screen-lg: 1560px !default;

:root {
    --headerTitle: #ffffff;
    --subTitle: #d7d7d8;
    --backgroundMain: #2c3e50;
    --cardBorder: #005fbe;
    --cardBackground: #282d38;
}

.home {
    display: block;
    margin: auto;
    background-color: rgb(40, 45, 56);
}

.row {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.row.playRow>* {
    width: unset;
}

.defaultRow {
    margin: 0 3% 0 3%;
}

.stickyTop {
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
    top: 0;
}

.overlay {
    width: 100%;
    z-index: 9;
    margin: 0;
    background-color: var(--headerTitle);
    opacity: 0.7;
    color: var(--headerTitle);
}

.statusBgd {
    background-color: #28a745;
}

.statusRow {
    margin-bottom: 0;
    justify-content: center;
}

.devOffline {
    background-color: #dee8fd40;
}

.blink-bg {
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
}

.blink-yellow {
    // animation: blinkYellow 4s infinite;
    background-color: #ddce00;
}

.blink-red {
    // animation: blinkRed 4s infinite;
    background-color: #800101;
}

.blink-green {
    // animation: blinkGreen 4s infinite;
    background-color: #018001;
}

@keyframes blinkGreen {
    0% {
        background-color: #018001;
    }

    60% {
        background-color: #018001e5;
    }

    70% {
        background-color: #018001c5;
    }

    80% {
        background-color: #01800173;
    }

    90% {
        background-color: #01800141;
    }

    100% {
        background-color: #01800121;
    }
}

@keyframes blinkRed {
    0% {
        background-color: #800101;
    }

    70% {
        background-color: #800101c5;
    }

    80% {
        background-color: #80010165;
    }

    90% {
        background-color: #80010128;
    }

    100% {
        background-color: #80010100;
    }
}

@keyframes blinkYellow {
    0% {
        background-color: #ddce00;
    }

    70% {
        background-color: #ddce00c9;
    }

    80% {
        background-color: #ddce0088;
    }

    90% {
        background-color: #ddce0054;
    }

    100% {
        background-color: #ddce0000;
    }
}

.statusLabel {
    color: #d7d7d8;
    margin: 0;
}

.statusVal {
    font-size: 1rem;
    margin-left: 2%;
    color: #d7d7d8;
}

.statusText {
    margin-bottom: 2px;
}

.cust-col {
    padding: 0;
}

.editUnit {
    min-width: 150px;
    margin: 0 0 5px 0;
    display: flex;
}

.unitText {
    display: inline-block;
    color: black;
    padding: 5px;
    margin-bottom: 0;
}

.paramText {
    color: black;
    margin: auto;
}

.deg-switch {
    display: inline-block;
    color: rgb(6, 78, 161);
    padding: 0 0 0 8px;
    margin-left: 5px;
}

.util-tools {
    color: #d7d7d8;
    height: 30px;
    width: 30px;
    margin: 5px;
}

.bi.util-tools {
    width: 30px;
    height: 30px;
}

.util-opts {
    display: flex;
    float: right;
}

.form-check-label {
    color: #d7d7d8;
    margin-left: 5px;
}

.util-text {
    color: #d7d7d8;
    display: flex;
    float: left;
    margin: 5px auto 5px 5px;
}

.play-text {
    color: #d7d7d8;
}

.playRow {
    width: 100%;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
}

.playgroundHeader {
    text-align: center;
    font-family: sans-serif;
    margin-bottom: 0;
    margin-left: 10px;
    padding-left: 10px;
    color: #ffffff;
}

.playgroundText {
    color: #d7d7d8;
    display: flex;
    // padding: 0;
    margin-bottom: 3px;
}

.playgroundInput {
    max-width: 100px;
    min-width: 60px;
    max-height: 30px;
}

.playgroundCard {
    display: block;
    border-top: 3px solid rgb(42, 59, 94);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    margin: 5px;
    padding: 5px;
    // min-height: 74px;
    // max-height: 300px;
    background-color: #313846;
}

.playgroundChart {
    height: 450px;
    width: 100%;
    position: relative;
}

.exprt-form {
    display: block;
    min-height: 50px;
    min-width: 200px;
    max-width: 400px;
    margin-left: 10px;
}

.util-sub-head {
    display: flex;
    color: #d7d7d8;
    font-size: 14px;
    align-items: flex-end;
}

.util-p {
    display: block;
    min-width: 80px;
}

.btn {
    background-color: rgb(50, 59, 77);
    border: none;
}

.img {
    min-width: 70px;
}

.deviceActiveImg {
    width: 40px;
}

#batt {
    margin-left: 20px;
}

#battPercent {
    :hover {
        display: show;
    }
}

#mapBox {
    display: block;
    height: 94%;
    min-height: 250px;

    #device {
        display: block;
        float: left;
        color: white;
        margin-left: 5px;
    }

    #gps {
        display: block;
        float: left;
        clear: both;
        width: 100%;
        margin: auto;
        color: white;
        font-size: 16px;
        height: min-content;

        span {
            display: block;
            float: right;
            clear: none;
            width: 100%;
            color: #d7d7d8;
            text-align: center;
        }
    }
}

#weatherBox {
    display: block;
    min-height: 250px;

    .Weather {
        display: block;
    }
}

#doughnut {
    padding: 0;
}

.lineChart {
    height: 300px;
    width: 100%;
    position: relative;
}

.card {
    display: block;
    border-top: 3px solid rgb(42, 59, 94);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    margin: 0px;
    padding: 5px;
    // min-height: 74px;
    // max-height: 300px;
    border-top: solid 2px rgb(6, 78, 161);
    background-color: rgb(50, 59, 77);

    &.doughnut-chart {
        overflow: hidden;
    }

    .device-title {
        font-size: 14px;
        color: #d7d7d8;
        padding: 5px;
        text-align: center;

        .text {
            color: black;
        }
    }

    .title {
        display: block;
        float: left;
        margin-bottom: 6%;
        font-size: 14px;
        color: #d7d7d8;

        .text {
            color: black;
        }
    }
}

.chart-value {
    text-align: center;
    font-size: 15px;
    color: white;
    max-width: 100px;
    line-height: 22px;
    margin: auto;
    margin-top: -40px;
    // margin-bottom: 40px;
}

.battStatImg {
    max-width: 60px;
    margin-left: 20%;
    margin-right: 10%;
    // margin-top: 5px;
}

.alert-icon {
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    font-size: 20px;
    color: #9698a2;
}

.btn-group {
    display: flex;

    .btn-content {
        flex: 1;
    }
}

.card .dropdown-menu.show {
    --bs-dropdown-bg: rgba(255, 0, 0, 0.0);
}

#dropdown-1 {
    display: flex;
    flex-wrap: wrap;
    color: white;
    min-width: 200px;

    ul {
        background-color: rgba(40, 45, 56, 0);
        border: solid 1px rgba(40, 45, 56, 0);
        border-radius: 5px;
        width: 100%;
        margin-left: -10px;

        li {
            li:first-child {
                margin-top: -30px;
            }

            border: solid 2px rgb(40, 45, 56);
            border-radius: 5px;
            color: white;
            text-align: left;

            img {
                margin-bottom: 5px;
                padding-right: 20px;
                margin-left: -30px;
            }
        }
    }
}

#dropdown-start {
    display: flex;
    // max-width: 600px;
    width: 300px;
    // width: 30%;
    margin: auto;
    margin-top: 70px;
    padding-bottom: 350px;

    ul {
        background-color: rgb(40, 45, 56);
        border: rgb(40, 45, 56);
        width: 100%;

        li {
            li:first-child {
                margin-top: -10px;
            }

            color: white;
            font-size: 1.1rem;
            text-align: left;
            margin-bottom: 7px;

            img {
                margin-bottom: 5px;
                padding-right: 20px;
            }
        }
    }
}

.dropdown-item {
    color: white;
    width: 100%;
}

.btn-dark:hover {
    background-color: white;
    color: black;
}

.sigStatImg {
    max-width: 50px;
    margin-left: 15%;
    margin-right: 10%;
}

.calendarBtn {
    .btn {
        background-color: #f3f3f5;
        border-width: 0;
    }
}

@media (max-width: ($screen-xs - 1)) {
    .title {
        font-size: 12px;
    }

    .chart-value {
        font-size: 10px;
        max-width: 60px;
        margin-top: -30px;
        line-height: inherit;
    }

    .refresh {
        margin-left: 45%;
    }
}

@media (max-width: ($screen-sm - 1)) {
    .playrow {
        width: 100%;
    }

    .col-12 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    #dropdown-menu {
        font-size: 1.2rem;
    }

    .welcome {
        display: none;
    }

    .time {
        margin-left: auto;
    }

    .defaultRow {
        margin: 5px 3% 5px 3%;
        // flex-direction: column;
        // flex-grow: 1;
    }

    #device {
        // font-size: 1.5rem;
        margin: 0;
    }

    .customChartButton {
        // font-size: 1.1rem;
        width: 60%;
        min-height: 45px;
    }

    #mapBox {
        min-height: 400px;
        margin-bottom: 1%;
    }

    #doughnut {
        // padding: 1% 2%;
        margin-bottom: 10px;
    }

    .title {
        font-size: 12px;
    }

    .chartjs-render-monitor {
        max-height: 400px;
    }
}

@media (min-width: $screen-sm) {
    .card {
        margin: 8px;

        .title {
            font-size: 14px;
            padding: 0 15px;
            text-align: center;
        }
    }
}

@media (min-width: $screen-lg) {
    #dropdown-1 {
        display: flex;
    }
}

.customChartButton {
    width: unset;
    color: #fff;
    border-radius: 0.25rem;
    line-height: 1.5;
    font-weight: 400;
}
</style>
  
<script lang="ts">
import router from "../router";
import { deviceRoot } from "../vue-client";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import SlotMap from "../components/SlotMap.vue";
import SensorGauges from "../components/SensorGauges.vue";
import LineChart from "../components/LineChart.vue";
import WeatherWidget from "../components/WeatherWidget.vue";
import SlotNote from "../components/SlotNote.vue";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { calibrateHistory } from "../utilities/scaleHistory";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import LinePlugin from "../plugins/LinePlugin";
import { useAuthStore } from "../stores/authStore";
import { useSlotStore } from "../stores/slotStore";
import { usePreferenceStore } from "../stores/preferenceStore";
import { useDurationStore } from "../stores/durationStore";
import {
    fromSlotsToSummaries,
    removeDuplicates,
    getDefaultOptions,
    getLineChartColor,
    getReportMinMax,
    getAxesMinMaxStep,
    featureEnabled
} from "../utilities/slotUtilities";
import { storeToRefs } from "pinia";
import { getImage } from "../utilities/annotationImages";
import { callAwakeFunction } from "../utilities/awakeUtilities";
import TimeSeriesPlots from "../components/timeSeriesPlots.vue";
import TrackerTracesPlots from "../components/trackerTracesPlots.vue";
import TrackerTracesGauges from "../components/trackerTracesGauges.vue";
import { ExportUtility } from "../utilities/exportUtility.js";

dayjs.extend(utc);
dayjs.extend(tz);

export default {
    components: {
        LineChart,
        SensorGauges,
        WeatherWidget,
        VueSlider,
        Header,
        Footer,
        // Cal,
        Loading,
        SlotMap,
        SlotNote,
        TimeSeriesPlots,
        TrackerTracesPlots,
        TrackerTracesGauges
    },
    setup() {
        const authStore = useAuthStore();
        if (!authStore.authUser) {
            router.push({ name: "signIn" });
        }
    },
    data() {
        return {
            outDat: null,
            addDeviceModal: false,
            timeSeriesNames: [],
            usersSelected: [],
            newDeviceName: "",
            newDeviceId: "",
            slotName: "",
            device: null,
            loaded: false,
            battery: 0,
            signal: 0,
            isART: false,
            window_open: false,
            hover: false,
            infoWindow: { lat: 0, lng: 0 },
            gmapName: "",
            gmapId: "",
            initDashboardMap: 5,
            isLoading: false,
            isStartLoading: true,
            quickLoadStart: false,
            fullPage: false,
            loaderBgnd: "rgb(50, 59, 77)",
            loaderType: "spinner",
            loaderOpacity: 0.75,
            loaderColor: "#ffffff",
            loaderHeight: 60,
            loaderWidth: 60,
            slotId: "",
            devAlerts: [],
            trendlineProperties: this.resetTrendline(),
            lastHeard: "",
            // deviceActive:true,
            deg: false,
            account: null,
            componentKey: 0,
            keyPCStatChart: 0,
            keyCAStatChart: 0,
            keyTUStatChart: 0,
            keyATStatChart: 0,
            keyWTStatChart: 0,
            keyLIStatChart: 0,
            keyCaPc: 0,
            keyWaterTemp: 0,
            keyBatSig: 0,
            keyTurb: 0,
            keyLight: 0,
            keyWaterTempTrend: 0,
            keyTurbTrend: 0,
            keyWaterTempStatChart: 0,
            keyAirTempStatChart: 0,
            keyCustom: 0,
            utI: 0,
            liI: 1,
            atI: 2,
            wtI: 3,
            tuI: 4,
            pcI: 5,
            caI: 6,
            wlI: 7,
            btI: 8,
            laI: 9,
            loI: 10,
            bvI: 11,
            bsI: 12,
            ssI: 13,
            sqI: 14,
            cvI: 15,
            pvI: 16,
            ldI: 17,
            deviceLat: 360,
            deviceLong: 360,
            inLongLat: false,
            deviceInWater: false,
            gpsLastUpdate: "GPS last update is unknown",
            smoothPlot: false,
            rawPlot: false,
            spikeRemovalFlag: false,
            fieldCalPlot: false,
            calPlotError: "",
            showCA: true,
            showPC: true,
            unitF: false,
            turbidityTrend: true,
            waterTrend: true,
            batHover: false,
            sigHover: false,
            exportModal: false,
            selectChartDatesModal: false,
            selectUnitsModal: false,
            createChartModal: false,
            chartPropertiesModal: false,
            adminSettingsModal: false,
            fieldCalModal: false,
            eventModal: false,
            calDate: null,
            calTime: null,
            calSensor: null,
            calSensorOptions: [
                { value: null, text: "Please Select Sensor" },
                { value: "phycocyanin", text: "Phycocyanin" },
                { value: "chlorA", text: "Chlorophyll-a" },
            ],
            fieldSample: null,
            calVal: null,
            calChartData: {},
            calChartOptions: {},
            verCalChart: false,
            calError: null,
            setCalTime: null,
            keyCalChart: 0,
            calSuccess: null,
            deviceOffline: false,
            annotationId: 0,
            //
            axesOpts: [
                { value: "left", text: "Left Axis" },
                { value: "right", text: "Right Axis" },
            ],
            sensorsSelected: [
                { sensor: "phycocyanin", axis: "left", selected: true, text: "Phycocyanin (PC)", feature: 'phycocyanin' },
                { sensor: "chlorA", axis: "left", selected: false, text: "Chlorophyll (CA)", feature: 'chlorA' },
                { sensor: "waterTemp", axis: "left", selected: false, text: "Water Temp.", feature: 'waterTemp' },
                { sensor: "ph", axis: "left", selected: false, text: "PH", feature: 'ph' },
                { sensor: "do", axis: "left", selected: false, text: "DO", feature: 'do' },
                { sensor: "ps", axis: "left", selected: false, text: "PS", feature: 'ps' },     
                { sensor: "turbidity", axis: "left", selected: false, text: "Turbidity (TU)", feature: 'turbidity' },
                { sensor: "light", axis: "left", selected: true, text: "Solar Light", feature: 'light' },
                { sensor: "battSOC", axis: "left", selected: false, text: "Battery", feature: 'battSOC' },
                { sensor: "sigStrength", axis: "left", selected: false, text: "Signal", feature: 'sigStrength' },
                { sensor: "panelVolts", axis: "left", selected: false, text: "Solar Panel", feature: 'panelVolts' },
                { sensor: "algaeRatio", axis: "left", selected: false, text: "Algae Ratio", feature: 'algaeRatio' },
                { sensor: "dayAvgPC", axis: "left", selected: false, text: "PC Daily Average", feature: 'phycocyanin' },
                { sensor: "dayAvgCA", axis: "left", selected: false, text: "CA Daily Average", feature: 'chlorA' },
                { sensor: "dayAvgTU", axis: "left", selected: false, text: "TU Daily Average", feature: 'turbidity' },
            ],
            customChart: [],
            showCustomChart: false,
            eventTable: [],
            eventFields: [
                { key: "date", label: "Date", sortable: true },
                { key: "event_type", label: "Event Type", sortable: true },
                { key: "delete_event", label: "Delete Event", sortabe: true },
            ],
            eventSelect: [],
            newEventSelected: null,
            newEventDateTime: null,
            newEventOptions: [
                { value: null, text: "Please select an option" },
                { value: "Treatment", text: "Treatment" },
                { value: "Rain", text: "Rain" },
            ],
            addEventError: null,
            eventSort: "date",
            eventPerPage: 5,
            eventCurrentPage: 1,
            startDate: null,
            endDate: null,
            alertEditModal: false,
            lowPermAlertEditModal: false,
            editChartData: {},
            editChartDataRangeValue: [0, 100],
            alertRangeSliderInterval: 0.001,
            alertRangeSliderMin: 0,
            alertRangeSliderMax: 100,
            alertMarks: [0, 100],
            datByCol: [],
            statCharts: [],
            reportLabels: [],
            turbTrendChart: null,
            waterTrendChart: null,
            algaeTimeReportsChart: null,
            waterTempLineChart: null,
            phLineChart: null,
            doLineChart: null,
            psLineChart: null,
            turbidityLineChart: null,
            batteryAndSingalReportsChart: null,
            battPVChart: null,
            signalAndPanelTimeReportsChart: null,
            ssLiChart: null,
            newDataArray: [],
            selectedReportId: null,
            doughnutOptions: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 2.5,
                circumference: 180,
                rotation: -90,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    },
                },
                borderWidth: 2
            },
            algaeLineChartOptions: null,
            battPVOptions: null,
            waterTempOptions: null,
            sigStrengthSolarOptions: null,
            turbidityOptions: null,
            customChartOptions: null,
            mapOptions: {
                // zoomControl: false,
                // scaleControl: false,
                // disableDefaultUI: true,
                // gestureHandling: "auto"//allow users to pan the map
                gestureHandling: "cooperative",
            },
            sensorGauges: [
                {
                    name: "light",
                    displayName: "Solar Light",
                    units: "uE/m²/sec",
                    maxValue: 2000,
                    minValue: 0,
                },
                {
                    name: "airTemp",
                    displayName: "Air Temperature",
                    units: "\u00B0 C",
                    maxValue: 40,
                    minValue: 0,
                },
                {
                    name: "waterTemp",
                    displayName: "Water Temperature",
                    units: "\u00B0 C",
                    maxValue: 40,
                    minValue: 0,
                },
                {
                    name: "turbidity",
                    displayName: "Turbidity",
                    units: "NTU",
                    maxValue: 400,
                    minValue: 0,
                },
                {
                    name: "phycocyanin",
                    displayName: "Phycocyanin",
                    units: "RFU",
                    maxValue: 1500,
                    minValue: 0,
                },
                {
                    name: "chlorA",
                    displayName: "Chlorophyll",
                    units: "RFU",
                    maxValue: 3000,
                    minValue: 0,
                }
            ],
            caTrendData: [],
            pcTrendData: [],
            pcAvgNightNPQ: [],
            caAvgNightNPQ: [],
            pcNPQTrendData: [],
            caNPQTrendData: [],
            turbCompData: [],
            pcTempCompData: [],
            showPlaygroundChart: false,
            playgroundChart: null,
            playgroundChartOptions: null,
            keyPlayground: 0,
            // playground data
            playPC: false,
            playCA: false,
            playTU: true,
            playBS: false,
            playPV: false,
            playSL: false,
            playWT: true,
            playSS: false,
            playPCTrend: false,
            playCATrend: false,
            playPCNPQ: false,
            playCANPQ: false,
            playPCNightAvg: false,
            playCANightAvg: false,
            playTUTrend: true,
            playPCTempComp: false,
            playPCAvg: false,
            playCAAvg: false,
            playTUAvg: false,
            playWTTrend: false,
            playLD: false,
            // axis data
            playPCAx: false,
            playCAAx: false,
            playTUAx: true,
            playBSAx: false,
            playPVAx: false,
            playSLAx: false,
            playWTAx: false,
            playSSAx: false,
            playLDAx: true,
            unitPlayTemp: true, //false when F
        };
    },
    async created() {
        await this.initDat();
        this.$watch(() => this.$route.params,
            async () => {
                await this.initDat();
            })
    },
    beforeDestroy: function () {
        this.device = null;
        this.loaded = false;
        this.datByCol = [];
        this.statCharts = [];
        this.algaeTimeReportsChart = null;
        this.batteryAndSingalReportsChart = null;
        this.battPVChart = null;
        this.signalAndPanelTimeReportsChart = null;
    },
    computed: {
        isLoggedIn: {
            get: function () {
                const authStore = useAuthStore();
                return authStore.authUser !== null;
            }
        },

        isViewerOnly: {
            get: function () {
                const authStore = useAuthStore();
                return authStore.authUser.role === 'user';
            }
        },

        SLOTS: function () {
            const slotStore = useSlotStore();
            return slotStore.slots;
        },
        deviceInWaterProperty: {
            get: function () {
                return this.deviceInWater;
            },
            set: function (val) {
                this.deviceInWater = val;
                // Make REST API call to save the data
                const slotStore = useSlotStore();
                slotStore.updateSlot(this.device._id, { inWater: this.deviceInWater }); // Don't wait
                const slotSummary = this.outDat.find(item => item.id === this.device._id);
                if (slotSummary) {
                    slotSummary.inWater = this.deviceInWater;
                }
            }
        },
        startDateProperty: { // Converting from DateTime to Date
            get: function () {
                return dayjs(this.startDate).format('YYYY-MM-DD');
            },
            set: function (val) {
                this.startDate = dayjs(val, { utc: false }).format();
                const durationStore = useDurationStore();
                const jsDate = dayjs(this.startDate).toDate();
                durationStore.updateStartDateTime(jsDate);
            }
        },
        endDateProperty: { // Converting from DateTime to Date
            get: function () {
                return dayjs(this.endDate).format('YYYY-MM-DD');
            },
            set: function (val) {
                this.endDate = dayjs(val, { utc: false }).format();
                const durationStore = useDurationStore();
                const jsDate = dayjs(this.endDate).toDate();
                durationStore.updateEndDateTime(jsDate);
            }
        },
        awakedMessage: function () {
            return "Wake Up Device";
        },
        eventRows() {
            return this.eventTable.length;
        },
        eventsProperty: {
            get: function () {
                const timeZone = dayjs.tz.guess();
                const events = [];
                for (let i = 0; i < this.eventTable.length; i++) {
                    const event = this.eventTable[i];
                    events.push({
                        id: event._id,
                        date: dayjs.utc(event.start_date).tz(timeZone)
                            .format("M/DD/YY h:mm a"),
                        event_type: event.name,
                        delete_event: false
                    });
                }

                return events;
            }
        }
    },
    // ** function calls
    methods: {
        goHome() {
            router.push({ name: `summary` });
        },
        goCompensated() {
            router.push({ name: `compensatedSlot`, params: { id: this.device._id } });
        },
        goCalibration() {
            router.push({ name: 'calibration', params: { id: this.device._id, featureId: this.device.feature.toString() } });
        },
        goToManageDevice() {
            router.push({ name: "manageDevice" });
        },
        openWindow(dat) {
            this.infoWindow = { lat: parseFloat(dat.latLong.lat), lng: parseFloat(dat.latLong.lng) };
            (this.gmapName = dat.name), (this.gmapId = dat.id), (this.window_open = true);
            // console.log(this.infoWindow);
        },
        featureEnabled(feature) {
            if (!this.device) {
                return true;
            }

            return featureEnabled(this.device, feature);
        },
        refreshPreference() {
            const preferenceStore = usePreferenceStore();
            const { preference } = storeToRefs(preferenceStore);
            this.waterTrend = preference.value.options.waterTrend;
            this.turbidityTrend = preference.value.options.turbidityTrend;
            this.smoothPlot = preference.value.options.smoothPlot;
            this.fieldCalPlot = preference.value.options.fieldCalPlot;
            this.rawPlot = preference.value.options.rawPlot;
            this.spikeRemovalFlag = preference.value.options.spikeRemovalFlag;
            this.unitF = preference.value.options.unitF;
            this.showCustomChart = preference.value.options.showCustomChart;
            this.showPlaygroundChart = preference.value.options.showPlaygroundChart;
            this.showCA = preference.value.options.showCA;
            this.showPC = preference.value.options.showPC;

            this.sensorsSelected.find(item => item.sensor === 'phycocyanin').selected = preference.value.options.phycocyaninSelected;
            this.sensorsSelected.find(item => item.sensor === 'chlorA').selected = preference.value.options.chlorASelected;
            this.sensorsSelected.find(item => item.sensor === 'waterTemp').selected = preference.value.options.waterTempSelected;
            this.sensorsSelected.find(item => item.sensor === 'ph').selected = preference.value.options.phSelected;
            this.sensorsSelected.find(item => item.sensor === 'do').selected = preference.value.options.doSelected;
            this.sensorsSelected.find(item => item.sensor === 'ps').selected = preference.value.options.psSelected;
            this.sensorsSelected.find(item => item.sensor === 'turbidity').selected = preference.value.options.turbiditySelected;
            this.sensorsSelected.find(item => item.sensor === 'light').selected = preference.value.options.lightSelected;
            this.sensorsSelected.find(item => item.sensor === 'battSOC').selected = preference.value.options.battSOCSelected;
            this.sensorsSelected.find(item => item.sensor === 'sigStrength').selected = preference.value.options.sigStrengthSelected;
            this.sensorsSelected.find(item => item.sensor === 'panelVolts').selected = preference.value.options.panelVoltsSelected;
            this.sensorsSelected.find(item => item.sensor === 'algaeRatio').selected = preference.value.options.algaeRatioSelected;
            this.sensorsSelected.find(item => item.sensor === 'dayAvgPC').selected = preference.value.options.dayAvgPCSelected;
            this.sensorsSelected.find(item => item.sensor === 'dayAvgCA').selected = preference.value.options.dayAvgCASelected;
            this.sensorsSelected.find(item => item.sensor === 'dayAvgTU').selected = preference.value.options.dayAvgTUSelected;

            this.deg = this.unitF; // This is the extra one
        },
        async initDat() {
            const authStore = useAuthStore();
            const slotStore = useSlotStore();
            const preferenceStore = usePreferenceStore();
            const durationStore = useDurationStore();
            this.startDate = durationStore.startDateTime;
            this.endDate = durationStore.endDateTime;

            this.isStartLoading = true;
            var allSlots = [];
            try {
                
                await slotStore.fetchAccountSlots();
                await slotStore.fetchAccountUsers();
                await preferenceStore.fetchPreference();
                await preferenceStore.fetchCompPreference();
                allSlots = slotStore.slots;
            } catch (err) {
                console.log(err);
            }

            let latTmp = [];
            let longTmp = [];
            this.outDat = await fromSlotsToSummaries(!!authStore.authUser, allSlots, latTmp, longTmp);

            if (authStore.authUser) {
                this.isART = authStore.isArt;
                this.refreshPreference();
            } else {
                this.isART = false;
            }
            // Set device ID
            const route = this.$route;
            const id = route.params.id; // read parameter id (it is reactive) 
            await this.populateDevice(id);
            this.isStartLoading = false;
        },
        async updateTrendLineProperties() {
            let trendPayload = this.trendlineProperties;
            const slotStore = useSlotStore();
            await Promise.all([
                slotStore.updateSlot(this.device._id, { trendlineProperties: trendPayload }),
            ]);
            await this.getReports(this.device._id);
        },
        async getSlotData() {
            const slotStore = useSlotStore();
            const slotData = slotStore.currentSlot;

            const props = slotData.trendlineProperties;
            if (
                props &&
                props.hasOwnProperty("pcProperties") &&
                props.hasOwnProperty("caProperties") &&
                props.hasOwnProperty("wtProperties") &&
                props.hasOwnProperty("tuProperties") &&
                props.hasOwnProperty("phProperties") &&
                props.hasOwnProperty("doProperties") &&
                props.hasOwnProperty("psProperties")
            ) {
                this.trendlineProperties = slotData.trendlineProperties;
            } else {
                const trendPayload = this.resetTrendline();
                this.trendlineProperties = trendPayload;
                await slotStore.updateSlot(this.device._id, { trendlineProperties: trendPayload });
            }
            if (slotData.deviceAlerts.length < 6) {
                const devAlerts = this.resetAlerts();
                const payload = { deviceAlerts: devAlerts };
                await slotStore.updateSlot(this.device._id, payload);
            }

            return;
            // console.log(this.trendlineProperties);
            // console.log(this.devAlerts);
        },
        async chartProperties() {
            this.deg = this.unitF;
            // Update the store
            const preferenceStore = usePreferenceStore();
            const patchBody = {
                unitF: this.unitF,
                waterTrend: this.waterTrend,
                turbidityTrend: this.turbidityTrend,
                showCustomChart: this.showCustomChart,
                showPlaygroundChart: this.showPlaygroundChart
            }

            await preferenceStore.updatePreference(patchBody);
            await this.getReports(this.device._id);
        },
        async chartCancel() {
            // Repopulate fields
            this.refreshPreference();
        },
        updateAwake: async function () {
            await callAwakeFunction(this.device.wakeRequest, this.device._id);
        },
        validateForm() {
            // convert to unix
            let st = Math.round(new Date(this.startDate).getTime() / 1000);
            let en = Math.round(new Date(this.endDate).getTime() / 1000);
            let timeDiff = en - st;
            if (timeDiff < 0) {
                alert("End Date Must Occur Before Start Date ");
                return false;
            }

            // set time to iso standards
            return true;
        },
        async exportCSV() {
            const authStore = useAuthStore();
            const slotStore = useSlotStore();
            let st = dayjs(this.startDate).toISOString();
            let en = dayjs(this.endDate)
                .set("hour", 23)
                .set("minute", 59)
                .toISOString();
            // const timeZone = await this.getTimeZone(this.deviceLat,this.deviceLong);
            const deviceTimeZone = slotStore.deviceTimeZone;
            if (deviceTimeZone.status === "OK") {
                st = dayjs.tz(this.startDate, deviceTimeZone.timeZoneId).toISOString();
                en = dayjs
                    .tz(this.endDate, deviceTimeZone.timeZoneId)
                    .set("hour", 23)
                    .set("minute", 59)
                    .toISOString();
            }

            let dID = this.device._id;

            const data = { startDate: st, endDate: en };
            let authString = "Bearer " + authStore.authUser.authToken;
            this.isLoading = true;
            const response = await fetch(`${deviceRoot}/devices/${dID}/history/v2`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authString,
                },
                body: JSON.stringify(data),
            });
            if (response.status !== 200) {
                alert("Error exporting data");
                return;
            }

            const hisReport = await response.json();
            // Populate timestamps
            hisReport.forEach((entry) => {
                entry.timestamp = new Date(entry.handshakeTime);
                entry.interpolated = false;
            });

            this.isLoading = false;
            let pubSensors = [
                "timeString",
                "utcTime",
                "light",
                "airTemp",
                "waterTemp",
                "turbidity",
                "phycocyanin",
                "chlorA",
                "waterLight",
                "buoyTemp",
                "latitude",
                "longitude",
                "battVolts",
                "battSOC",
                "sigStrength",
                "sigQuality",
                "codeVersion",
                "panelVolts",
                "leakDetect",
                "phycocyaninTrend",
                "chlorATrend",
                "turbidityTrend",
                'ph',
                'do',
                'ps'
            ];

            // Filter out the sensors that are not selected
            pubSensors = pubSensors.filter((sensorName) => {
                return this.featureEnabled(sensorName)
            });

            var str = ExportUtility.exportToCsv(hisReport, pubSensors, deviceTimeZone, this.device.trendlineProperties);     
            let outDat = "data:text/csv;charset=utf-8," + encodeURI(str);

            let fileName = this.endDate + ", " + this.device.name + ".csv";
            let link = document.createElement("a");
            link.href = outDat;
            link.target = "_blank";
            link.download = fileName;
            link.click();
            // this.exportModal=!this.exportModal;
        },
        async caPcChart(saveFlag) {
            if (saveFlag) {
                // Save preferences
                const preferenceStore = usePreferenceStore();
                const patchBody = {
                    rawPlot: this.rawPlot,
                    spikeRemovalFlag: this.spikeRemovalFlag,
                    smoothPlot: this.smoothPlot,
                    fieldCalPlot: this.fieldCalPlot,
                    showPC: this.showPC,
                    showCA: this.showCA
                }

                await preferenceStore.updatePreference(patchBody);

                // If spikeRemovedFlag is changed, we might have to repopulate the data, thus displayDeviceHistoryImpl is called
                const slotStore = useSlotStore();
                const deviceHistory = slotStore.computeCompensatedHistory();
                if (deviceHistory && deviceHistory.sensors && deviceHistory.sensors[0].history.length > 0) {
                    await this.displayDeviceHistoryImpl(deviceHistory, slotStore);
                }
            }
            this.calPlotError = "";
            var errorMsg = "";
            const dat = this.algaeTimeReportsChart.datasets;
            const pcDetailIndex = dat.findIndex((x) => x.label == "PC Detail");
            const pcTrendIndex = dat.findIndex((x) => x.label == "PC Trend");
            const pcCalIndex = dat.findIndex((x) => x.label == "PC Cal");
            const pcCalTrendIndex = dat.findIndex((x) => x.label == "PC Cal Trend");
            const caDetailIndex = dat.findIndex((x) => x.label == "CA Detail");
            const caTrendIndex = dat.findIndex((x) => x.label == "CA Trend");
            const caCalIndex = dat.findIndex((x) => x.label == "CA Cal");
            const caCalTrendIndex = dat.findIndex((x) => x.label == "CA Cal Trend");

            if (this.showPC) {
                // if pc is selected to view, show it
                dat[pcDetailIndex].hidden = !this.rawPlot;

                //  if calibration is done, we will never show the original trend.
                // In other words, trend will be shown only if this.smoothPlot is true and there is no calibration
                dat[pcTrendIndex].hidden = !this.smoothPlot || (this.fieldCalPlot && (dat[pcCalIndex].data.length !== 0));

                // If there is no calibration, we will never show pcCal. If the UI doesn't want to show cal plot, we won't show pcCal neither.
                dat[pcCalIndex].hidden = !this.fieldCalPlot || (dat[pcCalIndex].data.length === 0);

                // If the UI doesn't want to show smoothPlot, we will hide CalTrend. If there is no calibration, we will not show pcCalTrend.
                dat[pcCalTrendIndex].hidden = !this.smoothPlot || (dat[pcCalIndex].data.length === 0) || !this.fieldCalPlot;

                if (dat[pcCalIndex].data.length == 0 && this.fieldCalPlot) {
                    // if no field cal data, throw error
                    errorMsg += "Phycocyanin. ";
                }
            } else {
                dat[pcDetailIndex].hidden = true;
                dat[pcTrendIndex].hidden = true;
                dat[pcCalIndex].hidden = true;
                dat[pcCalTrendIndex].hidden = true;
            }

            if (this.showCA) {
                // if ca is selected to view, show it
                dat[caDetailIndex].hidden = !this.rawPlot;

                //  if calibration is done, we will never show the original trend.
                // In other words, trend will be shown only if this.smoothPlot is true and there is no calibration
                dat[caTrendIndex].hidden = !this.smoothPlot || (this.fieldCalPlot && (dat[caCalIndex].data.length !== 0));

                // If there is no calibration, we will never show caCal. If the UI doesn't want to show cal plot, we won't show caCal neither.
                dat[caCalIndex].hidden = !this.fieldCalPlot || (dat[caCalIndex].data.length === 0);

                // If the UI doesn't want to show smoothPlot, we will hide CalTrend. If there is no calibration, we will not show caCalTrend.
                dat[caCalTrendIndex].hidden = !this.smoothPlot || (dat[caCalIndex].data.length === 0) || !this.fieldCalPlot;

                if (dat[caCalIndex].data.length == 0 && this.fieldCalPlot) {
                    // if no field cal data, throw error
                    errorMsg += "Chlorophyll-a. ";
                }
            } else {
                dat[caDetailIndex].hidden = true;
                dat[caTrendIndex].hidden = true;
                dat[caCalIndex].hidden = true;
                dat[caCalTrendIndex].hidden = true;
            }

            var minMaxPC = [];
            var minMaxPCTrend = [];
            var maxPC = 0;
            var minMaxCA = [];
            var minMaxCATrend = [];
            var maxCA = 0;
            if (this.fieldCalPlot && !this.smoothPlot && !this.rawPlot) {
                // show only calibration data
                if (dat[pcCalIndex].data.length > 0) {
                    minMaxPC = getReportMinMax(dat[pcCalIndex].data, -0.2, 10);
                    maxPC = Math.ceil(minMaxPC[1] + minMaxPC[1] * 0.2);
                    this.algaeLineChartOptions.scales.y_axis_1.title = {
                        display: true,
                        text: "PC (ug/L)",
                        color: 'white'
                    }
                } else {
                    minMaxPCTrend = getReportMinMax(dat[pcTrendIndex].data, -0.2, 10);
                    maxPC = Math.ceil(minMaxPCTrend[1]);
                    this.algaeLineChartOptions.scales.y_axis_1.title = {
                        display: true,
                        text: "PC RFU",
                        color: 'white'
                    }
                }

                if (dat[caCalIndex].data.length > 0) {
                    minMaxCA = getReportMinMax(dat[caCalIndex].data, -0.2, 15);
                    maxCA = Math.ceil(minMaxCA[1] + minMaxCA[1] * 0.2);
                    this.algaeLineChartOptions.scales.y_axis_2.title = {
                        display: true,
                        text: "CA (ug/L)",
                        color: 'white'
                    }
                } else {
                    minMaxCATrend = getReportMinMax(dat[caTrendIndex].data, -0.2, 150);
                    maxCA = Math.ceil(minMaxCATrend[1]);
                    this.algaeLineChartOptions.scales.y_axis_2.title = {
                        display: true,
                        text: "CA RFU",
                        color: 'white'
                    }
                }
            } else {
                // show any other combo
                minMaxPC = getReportMinMax(dat[pcDetailIndex].data, -0.2, 10);
                minMaxPCTrend = getReportMinMax(dat[pcTrendIndex].data, -0.2, 10);
                maxPC = Math.ceil(Math.max(minMaxPC[1], minMaxPCTrend[1]));
                this.algaeLineChartOptions.scales.y_axis_1.title = {
                    display: true,
                    text: "PC RFU",
                    color: 'white'
                };

                minMaxCA = getReportMinMax(dat[caDetailIndex].data, -0.2, 150);
                minMaxCATrend = getReportMinMax(dat[caTrendIndex].data, -0.2, 150);
                maxCA = Math.ceil(Math.max(minMaxCA[1], minMaxCATrend[1]));
                this.algaeLineChartOptions.scales.y_axis_2.title = {
                    display: true,
                    text: "CA RFU",
                    color: 'white'
                };
            }
            this.algaeLineChartOptions.scales.y_axis_1.max = Math.ceil(maxPC);
            this.algaeLineChartOptions.scales.y_axis_1.ticks.stepSize = Math.round(maxPC / 10);

            this.algaeLineChartOptions.scales.y_axis_2.max = Math.ceil(maxCA);
            this.algaeLineChartOptions.scales.y_axis_2.ticks.stepSize = Math.round(maxCA / 10);

            this.algaeTimeReportsChart.datasets = dat;
            this.calPlotError = errorMsg;
            this.keyCaPc += 1;

            this.incrementKeys();
            this.$forceUpdate();
            this.allowDetailPage = true;
            this.loaded = true;
            this.isLoading = false;
        },
        async selectChartDates() {
            if (this.validateForm()) {
                await this.getReports(this.device._id);
            }
        },
        startText() {
            return "Choose Your Device";
        },
        getSlotName(slotId) {
            const c = this.SLOTS.find((x) => x._id == slotId);
            if (c) {
                return c.name;
            } else {
                return "Unknown device";
            }
        },
        setDeviceId(id) {
            router.push({ name: `slot`, params: { id } });
        },
        async populateDevice(slotId) {
            const slotStore = useSlotStore();
            const payload = { id: slotId };
            await slotStore.fetchSlot(payload);
            this.device = slotStore.currentSlot;
            this.slotName = this.device.name;
            // reset trend data
            this.trendlineProperties = this.resetTrendline();
            this.calPlotError = "";
            // get current data for slot - check trendline and device alerts
            await this.getSlotData();
            await this.getReports(slotId);
        },
        async saveAlert(e, editChartData, editChartDataRangeValue) {
            const slotStore = useSlotStore();
            if (
                (editChartData.name.toLowerCase() === "watertemp" ||
                    editChartData.name.toLowerCase() === "airtemp") &&
                !this.deg
            ) {
                // values displayed at F, need to save as C
                editChartDataRangeValue[0] = this.ftoC(editChartDataRangeValue[0]);
                editChartDataRangeValue[1] = this.ftoC(editChartDataRangeValue[1]);
            }
            if (!editChartData.alertActive) {
                editChartData.alertActive = false; // take care of null values
                // console.log("alertActive" + editChartData.alertActive);
            }

            let device = this.device;
            // update device -> deviceAlert
            if (device.deviceAlerts && device.deviceAlerts.length > 0) {
                //  find alert
                const devAlert = device.deviceAlerts.findIndex(
                    (x) => x.devicePropName == editChartData.name
                );
                // update
                if (devAlert >= 0) {
                    device.deviceAlerts[devAlert].active = editChartData.alertActive;
                    device.deviceAlerts[devAlert].low = editChartDataRangeValue[0];
                    device.deviceAlerts[devAlert].high = editChartDataRangeValue[1];
                } else {
                    // create
                    device.deviceAlerts.push({
                        devicePropName: editChartData.name,
                        active: editChartData.alertActive,
                        low: editChartDataRangeValue[0],
                        high: editChartDataRangeValue[1],
                    });
                }
            } else {
                // create alert and alertArray
                device.deviceAlerts.push({
                    devicePropName: editChartData.name,
                    active: editChartData.alertActive,
                    low: editChartDataRangeValue[0],
                    high: editChartDataRangeValue[1],
                });
            }
            const payload = { deviceAlerts: device.deviceAlerts };
            try {
                await slotStore.updateSlot(this.device._id, payload);
                device = slotStore.currentSlot;
            } catch (err) {
                console.log(err);
            }
            this.device = device;
            // update homepage
            const i = this.outDat.findIndex((x) => x.id == device._id);
            this.outDat[i].pcAlert = device.deviceAlerts.find(
                (x) => x.devicePropName == "phycocyanin"
            ).high;
            this.outDat[i].caAlert = device.deviceAlerts.find((x) => x.devicePropName == "chlorA").high;
            this.outDat[i].tbAlert = device.deviceAlerts.find(
                (x) => x.devicePropName == "turbidity"
            ).high;
            this.refreshUI();
        },
        editAlerts(chartData) {
            const slotStore = useSlotStore();
            var device = slotStore.currentSlot;
            let sensorName = chartData.sensorName;
            if (!sensorName) // featured sensor
            {
                const feature = chartData.feature;
                if (feature === 3) {
                    chartData.name = 'ph';
                    chartData.sensorName = 'ph';
                    chartData.displayName = 'PH';
                    chartData.alertActive = false;
                }
                else if (feature === 2) {
                    chartData.name = 'do';
                    chartData.sensorName = 'do';
                    chartData.displayName = 'DO';
                    chartData.alertActive = false;
                }
            }

            let alert = device.deviceAlerts.find((x) => x.devicePropName == chartData.sensorName);

            if (!alert) {
                const devAlerts = this.resetAlerts();
                alert = devAlerts.find((y) => y.devicePropName == chartData.name);
                device.deviceAlerts.push(alert);
            }

            const alertFormat = alert ? { name: alert.devicePropName, alertActive: alert.active } : null;

            this.editChartData = Object.assign({}, chartData, alertFormat ? alertFormat : {});

            const defaultGauges = this.resetAlerts();
            const deviceProp = defaultGauges.find((x) => x.devicePropName == chartData.sensorName);
            let high = deviceProp.high;
            let low = deviceProp.low;
            if (
                (alert.devicePropName.toLowerCase() === "watertemp" ||
                    alert.devicePropName.toLowerCase() === "airtemp") &&
                !this.deg
            ) {
                // in C need F
                this.alertRangeSliderMin = this.ctoF(low);
                this.alertRangeSliderMax = this.ctoF(high);

                this.editChartDataRangeValue = alert
                    ? [this.ctoF(Number(alert.low)).toFixed(0), this.ctoF(Number(alert.high)).toFixed(0)]
                    : [low + low * 0.25, high - high * 0.25];
            } else {
                this.alertRangeSliderMin = low;
                this.alertRangeSliderMax = high;

                this.editChartDataRangeValue = alert
                    ? [Number(alert.low).toFixed(0), Number(alert.high).toFixed(0)]
                    : [low + low * 0.25, high - high * 0.25];
            }   

            if (this.isViewerOnly) {
                this.lowPermAlertEditModal = !this.lowPermAlertEditModal;
            } else {
                this.alertEditModal = !this.alertEditModal;
            }
        },
        setRange(position, value) {
            if (
                (alert.devicePropName === "waterTemp" && !this.deg) ||
                (alert.devicePropName === "airTemp" && this.deg)
            ) {
                // want to display as F
                value = Number((value * (9 / 5) + 32).toFixed(0));
            }
            this.editChartDataRangeValue[position] = parseFloat(value);
            this.$refs.alertRangeSlider.setValue(this.editChartDataRangeValue);
        },
        alertRangeSliderChanged() {
            const currentSliderVal = this.$refs.alertRangeSlider.getValue();
            this.editChartDataRangeValue = currentSliderVal;
        },
        async refreshUI() {
            this.endDate = new Date(Date.now()).toISOString();
            await this.getReports(this.device._id);
        },

        /* Field calibrations
        Control POST, FETCH calls to slot
        */
        async getFieldCals(slotId) {
            const authStore = useAuthStore();
            let authString = "Bearer " + authStore.authUser.authToken;
            const response = await fetch(`${deviceRoot}/slots/${slotId}/fieldcal`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authString,
                },
            });
            const cals = await response.json();
            return cals;
        },
        async createNewFieldCals(slotId, newCal) {
            const authStore = useAuthStore();
            let authString = "Bearer " + authStore.authUser.authToken;
            const create = await fetch(`${deviceRoot}/slots/${slotId}/fieldcal`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authString,
                },
                body: JSON.stringify(newCal),
            });
            const created = await create.json();
            return created;
        },
        async updateFieldCals(cal) {
            const authStore = useAuthStore();
            let authString = "Bearer " + authStore.authUser.authToken;
            var slotId = cal.slot_id;
            var calId = cal._id;
            const create = await fetch(`${deviceRoot}/slots/${slotId}/updatefieldcal/${calId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authString,
                },
                body: JSON.stringify(cal),
            });
            const created = await create.json();
            return created;
        },
        async postCal(calSensor, calVal, fieldSample) {
            // post calibration to db
            if (!this.setCalTime || !calSensor) {
                return (this.calError =
                    "Error in attempting to calibrate.  Please check fields and try again.");
            }
            var slotId = this.device._id;
            // let slotId = this.slotId;
            const cals = await this.getFieldCals(slotId);
            if (cals.length == 0 || !cals.find((x) => x.sensorName == calSensor)) {
                // create cal log
                const newCal = {
                    sensorName: calSensor,
                    slot_id: slotId,
                    moments: [
                        {
                            activated_at: this.setCalTime,
                            rfu: calVal,
                            ugL: fieldSample,
                        },
                    ],
                };
                const created = await this.createNewFieldCals(slotId, newCal);
                if (created.sensorName) {
                    this.calSuccess =
                        "Successfully calibrated " +
                        created.sensorName +
                        ".  Enter another calibration or select done.";
                }
            } else {
                var appendCal = {
                    activated_at: this.setCalTime,
                    rfu: calVal,
                    ugL: fieldSample,
                };
                const prevCals = cals.find((x) => x.sensorName == calSensor);
                prevCals.moments.push(appendCal);
                const updated = await this.updateFieldCals(prevCals);
                if (updated.sensorName) {
                    this.calSuccess =
                        "Successfully calibrated " +
                        updated.sensorName +
                        ".  Enter another calibration or select done.";
                }
            }
        },
        async checkCal(calDate, calTime, calSensor) {
            if (!calDate || !calTime || !calSensor) {
                this.calError = "One or more inputs is missing.  Please check form.";
                return;
            }
            // user will input sensor, field ug/l value and date/time
            // const timeZone = await this.getTimeZone(this.device.gpsLat,this.device.gpsLong);
            const slotStore = useSlotStore();
            const deviceTimeZone = slotStore.deviceTimeZone;
            var start = "";
            var end = "";
            var unixTime = "";
            if (deviceTimeZone.rawOffset) {
                unixTime = dayjs.tz(calDate + " " + calTime, deviceTimeZone.timeZoneId).unix();
                this.setCalTime = dayjs.tz(calDate + " " + calTime, deviceTimeZone.timeZoneId)
                    .utc()
                    .format();
                var s = dayjs.tz(calDate + " " + calTime, deviceTimeZone.timeZoneId).subtract(4, "hours");
                var e = dayjs.tz(calDate + " " + calTime, deviceTimeZone.timeZoneId).add(4, "hours");
                start = s.utc().format();
                end = e.utc().format();
            } else {
                unixTime = dayjs(calDate + " " + calTime).unix();
                this.setCalTime = new Date(calDate + " " + calTime).toISOString();
                start = new Date(new Date(calDate + " " + calTime) - 4 * 60 * 60 * 1000).toISOString();
                end = new Date(new Date(calDate + " " + calTime) + 4 * 60 * 60 * 1000).toISOString();
            }
            const payload = { slotId: this.device._id, startDate: start, endDate: end };
            await slotStore.fetchDeviceHistory(payload);
            const deviceHist = slotStore.computeCompensatedHistory(); // Cloned version
            // const deviceHist = await this.getDeviceHistory(start,end,this.device._id);

            // get data to calibrate to
            if (deviceHist && deviceHist.sensors[0].history.length > 0) {
                // find RFU of closest data point
                var diff = deviceHist.sensors[0].history.flatMap((x) => Math.abs(x.val - unixTime));
                var minDiff = Math.min(...diff);
                var index = diff.findIndex((x) => x == minDiff);
                var sensorData = deviceHist.sensors.find((x) => x.sensorName === calSensor);
                var val = sensorData.history[index].val;
                this.calVal = val;
                var i = diff.length - index - 1;
                var sensorLineLabels = [];
                if (deviceTimeZone.rawOffset) {
                    sensorLineLabels = deviceHist.sensors[0].history
                        .flatMap((x) => dayjs.tz(x.val * 1000, deviceTimeZone.timeZoneId).format("hh:mm"))
                        .reverse();
                } else {
                    sensorLineLabels = deviceHist.sensors[0].history
                        .flatMap((x) => dayjs(x.val * 1000).format("hh:mm"))
                        .reverse();
                }

                var sensorLineData = sensorData.history.flatMap((x) => x.val).reverse();
                var calChartData = {
                    labels: sensorLineLabels,
                    datasets: [
                        {
                            label: calSensor,
                            data: sensorLineData,
                            pointBackgroundColor: function (context) {
                                var index = context.dataIndex;
                                var value = context.dataset.data[index];
                                return value == val && index == i ? "#de0d0d" : "#c3c8c9";
                            },
                            pointRadius: function (context) {
                                var index = context.dataIndex;
                                var value = context.dataset.data[index];
                                return value == val && index == i ? 10 : 3;
                            },
                            pointStyle: function (context) {
                                var index = context.dataIndex;
                                var value = context.dataset.data[index];
                                return value == val && index == i ? "star" : "circle";
                            },
                            pointBorderColor: function (context) {
                                var index = context.dataIndex;
                                var value = context.dataset.data[index];
                                return value == val && index == i ? "#de0d0d" : "#c3c8c9";
                            },
                            pointBorderWidth: function (context) {
                                var index = context.dataIndex;
                                var value = context.dataset.data[index];
                                return value == val && index == i ? 3 : 1;
                            },
                        },
                    ],
                };
                this.calChartData = calChartData;
                var calChartOptions = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            intersect: false,
                            axis: "x",
                            backgroundColor: "#000000",
                        },
                    },
                    scales: {
                        y_axis_1:
                        {
                            gridLines: {
                                display: true,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: "RFU",
                            },
                        },
                    },
                };
                this.calChartOptions = calChartOptions;
                this.calError = null;
                this.calSuccess = null;
                this.verCalChart = true;
                this.keyCalChart += 1;
                this.$forceUpdate();
            } else {
                this.calError =
                    "No AlgaeTracker data for " +
                    calDate +
                    " " +
                    calTime +
                    ".  Please adjust the date range.";
            }
        },
        async applyCal(calSensor, calVal, fieldSample) {
            await this.postCal(calSensor, calVal, fieldSample);
            await this.getReports(this.slotId);
            // this.fieldCalModal = !this.fieldCalModal;
            return;
        },
        async getEvent(slotId) {
            const authStore = useAuthStore();
            let authString = "Bearer " + authStore.authUser.authToken;
            const response = await fetch(`${deviceRoot}/slots/${slotId}/events`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authString,
                },
            });
            const events = await response.json();
            return events;
        },
        async getEventsGlobal(slotId, deviceTimeZone) {
            // get custom events
            const events = await this.getEvent(slotId);
            this.eventTable = events;
            this.updateAnnotations();
            return;
        },
        async createEvent(slotId, event) {
            const authStore = useAuthStore();
            let authString = "Bearer " + authStore.authUser.authToken;
            const create = await fetch(`${deviceRoot}/slots/${slotId}/events`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authString,
                },
                body: JSON.stringify(event),
            });
            const created = await create.json();
            return created;
        },
        onEventSelected(items) {
            this.eventSelect = items;
        },
        async addEvent() {
            if (this.newEventDateTime == null || this.newEventSelected == null) {
                this.addEventError = "please select treatment type, date and time";
            } else {
                let dateTime = dayjs(this.newEventDateTime);
                console.log(dateTime);
                let ev = this.newEventSelected;
                let payload = {
                    name: ev,
                    start_date: dateTime,
                };
                let created = await this.createEvent(this.slotId, payload); // create event
                this.eventTable.push(created);
                this.updateAnnotations();

                this.addEventError = null;
                this.newEventDateTime == null;
            }
        },
        async deleteEvent(row) {
            // delete eventst selected in eventSelect
            const authStore = useAuthStore();
            const slotStore = useSlotStore();
            let authString = "Bearer " + authStore.authUser.authToken;
            let fetchObj = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authString,
                },
            };

            let fetchString = `${deviceRoot}/slots/${this.slotId}/events/${row.item.id}`;
            await fetch(fetchString, fetchObj);
            // now re-create table
            await this.getEventsGlobal(this.slotId, slotStore.deviceTimeZone);
        },

        updateAnnotations() {
            if (!this.algaeTimeReportsChart) {
                return;
            }

            // use this.eventTable, always updated and don't have to fetch events from slots
            let annotationsPcCa = [];
            let annotationsTurb = [];
            console.log(this.eventTable);
            for (const event of this.eventTable) {
                if (event && event.start_date) {
                    const eventDate = dayjs(new Date(event.start_date));
                    if (
                        eventDate >= dayjs(new Date(this.algaeTimeReportsChart.labels[0])) &&
                        eventDate <= dayjs(new Date(this.algaeTimeReportsChart.labels.slice(-1)[0]))
                    ) {
                        const dateOnChart = dayjs(this.getClosestDateOnChart(eventDate)).format(
                            "M/DD/YY h:mm a"
                        );
                        console.log("Computed date on chart: " + dateOnChart);
                        const annotationImg = getImage(event.event_type);
                        annotationsPcCa.push({
                            type: "line",
                            id: "line" + this.annotationId,
                            drawTime: "afterDatasetsDraw",
                            mode: "vertical",
                            scaleID: "x_axis_0",
                            value: dateOnChart,
                            borderWidth: 5,
                            borderColor: this.getAnnotationColor(event.event_type),
                            eventType: event.event_type,
                            label: {
                                /*
                                position: "end",
                                backgroundColor: 'white',
                                content: annotationImg,
                                width: '20%',
                                height: '20%',
                                display: true
                                */
                                enabled: false,
                                position: "bottom",
                                // content: image,
                                content: dayjs(new Date(event.start_date)).format("M/DD h:mm a"),
                            },
                            onMouseenter(e) {
                                console.log("mouseenter");

                                // this.options.label.enabled = true;
                                //    this.appInstance.keyCaPc += 1;
                                // this.$root.keyTurb += 1;

                                this.options.helper(this.options.value, this.options.eventType);
                                console.log("Running...");
                            },

                            onMouseleave(e) {
                                console.log("mouseleave");
                                this.options.helper2(this.options.value, this.options.eventType);
                                console.log("Running...");
                            },

                            helper: (date, type) => {
                                console.log(this.algaeLineChartOptions.plugins.annotation.annotations);
                                console.log(date);
                                for (const line of this.algaeLineChartOptions.plugins.annotation.annotations) {
                                    console.log(line);

                                    if (line.value === date) {
                                        line.label.enabled = true;
                                        line.borderColor = this.getAnnotationColor(type + "_bold");
                                        //!line.label.enabled;
                                    }
                                }
                                this.keyCaPc += 1;
                            },

                            helper2: (date, type) => {
                                console.log(this.algaeLineChartOptions.plugins.annotation.annotations);
                                console.log(date);
                                for (const line of this.algaeLineChartOptions.plugins.annotation.annotations) {
                                    console.log(line);

                                    if (line.value === date) {
                                        line.label.enabled = false;
                                        line.borderColor = this.getAnnotationColor(type);
                                        //!line.label.enabled;
                                        console.log(line);
                                    }
                                }
                                this.keyCaPc += 1;
                            },
                        });

                        this.annotationId++;

                        annotationsTurb.push({
                            type: "line",
                            id: "line" + this.annotationId,
                            drawTime: "afterDatasetsDraw",
                            mode: "vertical",
                            scaleID: "x_axis_0",
                            value: dateOnChart,
                            borderWidth: 5,
                            borderColor: this.getAnnotationColor(event.event_type),
                            eventType: event.event_type,
                            label: {
                                enabled: false,
                                position: "bottom",
                                // content: image,
                                content: dayjs(new Date(event.date)).format("M/DD h:mm a"),
                            },

                            onMouseenter(e) {
                                console.log("mouseenter");

                                // this.options.label.enabled = true;
                                //    this.appInstance.keyCaPc += 1;
                                // this.$root.keyTurb += 1;

                                this.options.helper(this.options.value, this.options.eventType);
                                console.log("Running...");
                            },

                            onMouseout(_) {
                                console.log("mouseleave");
                                this.options.helper2(this.options.value, this.options.eventType);
                                console.log("Running...");
                            },

                            helper: (date, type) => {
                                console.log(this.turbidityOptions.plugins.annotation.annotations);
                                console.log(date);
                                for (const line of this.turbidityOptions.plugins.annotation.annotations) {
                                    console.log(line);

                                    if (line.value === date) {
                                        line.label.enabled = true;
                                        line.borderColor = this.getAnnotationColor(type + "_bold");
                                        //!line.label.enabled;
                                    }
                                }

                                this.keyTurb += 1;
                            },

                            helper2: (date, type) => {
                                console.log(this.turbidityOptions.plugins.annotation.annotations);
                                console.log(date);
                                for (const line of this.turbidityOptions.plugins.annotation.annotations) {
                                    console.log(line);

                                    if (line.value === date) {
                                        line.label.enabled = false;
                                        line.borderColor = this.getAnnotationColor(type + "_bold");
                                        //!line.label.enabled;
                                        console.log(line);
                                    }
                                }

                                this.keyTurb += 1;
                            },
                        });
                        this.annotationId++;
                        // annotations on both the CA/PC chart and the turbidity chart
                        this.algaeLineChartOptions.plugins.annotation.annotations = annotationsPcCa;

                        this.turbidityOptions.plugins.annotation.annotations = annotationsTurb;
                    }

                    // this.algaeLineChartOptions.annotation.annotations.push({
                    //   type: "line",
                    //   mode: "vertical",
                    //   xMin: dateOnChart,
                    //   xMax: dateOnChart,
                    //   borderWidth: 2,
                    // });
                    // this.turbidityOptions.annotation.annotations.push({type: "line", mode:"vertical"});
                }
            }
            this.keyCaPc += 1;
            this.keyTurb += 1;
        },

        getClosestDateOnChart(eventDate) {
            const arr = this.algaeTimeReportsChart.labels.map((x) => new Date(x));
            arr.sort((a, b) => {
                var distanceA = Math.abs(a - eventDate);
                var distanceB = Math.abs(b - eventDate);
                return distanceA - distanceB;
            });
            return arr[0];
        },
        getLastHeard(slotId) {
            const currentSlotIndex = this.outDat.findIndex((x) => x.id == slotId);
            return this.outDat[currentSlotIndex].lastHeard;
        },
        async displayDeviceHistoryImpl(deviceHistory, slotStore) {
            // TODO: perform compensation here?

            if (this.spikeRemovalFlag) {
                slotStore.performSpikeRemoval(deviceHistory);
            }

            const deviceSnapshot = slotStore.deviceSnapshot;

            this.deviceOffline = false;
            this.assignSensorIndices(deviceHistory); // assign sensor indices
            let numSensors = deviceHistory.sensors.length;

            // sort object by utcTime (for synching data)
            let lenDat = [];
            for (let c = 0; c < numSensors; c++) {
                lenDat.push(deviceHistory.sensors[c].history.length);
            }
            lenDat = Math.max.apply(Math, lenDat);
            let fullDat = [];
            for (let i = 0; i < lenDat; i++) {
                let a = [];
                for (let t = 0; t < numSensors; t++) {
                    if (i < deviceHistory.sensors[t].history.length) {
                        a.push(deviceHistory.sensors[t].history[i].val);
                    } else {
                        a.push(-1);
                    }
                }
                fullDat.push(a);
            }
            fullDat.sort((first, last) => first[this.utI] - last[this.utI]);
            // remove duplicates
            fullDat = removeDuplicates(fullDat);
            // NPQ comp
            this.NPQComp(fullDat);

            let datByCol = fullDat[0].map((_, colIndex) => fullDat.map((row) => row[colIndex]));
            this.datByCol = datByCol;

            lenDat = datByCol[0].length;

            /* set lat and long */
            if (this.device.useDefaultGPS === false) {
                const latitude = deviceSnapshot.deviceProps[this.laI].result;
                const longitude = deviceSnapshot.deviceProps[this.loI].result;
                var gpsLastUpdate = datByCol[this.utI][lenDat - 1];
                if (latitude !== 1 && longitude !== 1 && latitude !== 0 && longitude !== 0) {
                    this.device.gpsLat = latitude;
                    this.device.gpsLong = longitude;
                }
            }

            this.deviceLat = this.device.gpsLat;
            this.deviceLong = this.device.gpsLong;
            this.inLongLat = true;
            this.deviceInWater = this.device.inWater;

            /* NOW do time zone, weather */
            var tzPayload = {
                lat: this.deviceLat,
                lng: this.deviceLong,
            };

            await slotStore.fetchWeatherLabel(tzPayload);
            await slotStore.fetchTimeZone(tzPayload);

            this.timeSeriesNames = ['precipitationIntensity', 'windSpeed']; // Trigger refresh of time series

            const deviceTimeZone = slotStore.deviceTimeZone;
            this.battery = fullDat[lenDat - 1][this.bsI].toFixed(1);
            this.signal = fullDat[lenDat - 1][this.ssI].toFixed(1);

            // this new array can be added to with trend data etc
            var newDataArray = [];
            for (let i = 0; i < numSensors; i++) {
                var [label, units] = this.assignChartTitles(deviceHistory.sensors[i].sensorName);
                newDataArray.push({
                    sensorName: deviceHistory.sensors[i].sensorName,
                    history: datByCol[i],
                    label: label,
                    units: units,
                });
            }
            // const timeZone = await this.getTimeZone(this.deviceLat,this.deviceLong);
            var timeString = [];
            var utcTime = newDataArray.find((x) => x.sensorName == "utcTime").history;
            if (deviceTimeZone.status === "OK") {
                timeString = utcTime.map((y) =>
                    dayjs.tz(y * 1000, deviceTimeZone.timeZoneId).format("M/DD/YY h:mm a")
                );
                this.gpsLastUpdate =
                    "GPS last updated: " +
                    dayjs.tz(gpsLastUpdate * 1000, deviceTimeZone.timeZoneId).format("M/DD h:mm a");
            } else {
                timeString = utcTime.map((y) => dayjs(y * 1000).format("M/DD/YY h:mm a"));
                this.gpsLastUpdate =
                    "GPS last updated: " + dayjs(gpsLastUpdate * 1000).format("M/DD h:mm a");
            }
            newDataArray.push({
                sensorName: "timeString",
                history: timeString,
            });
            this.lastHeard = this.getLastHeard(this.slotId);

            // save data array to use as extern
            this.newDataArray = newDataArray;

            /*  START CAL AND TREND ANALYSIS */

            // get trendline and calibration data
            let props = this.trendlineProperties;
            const cals = await this.getFieldCals(this.slotId);
            await this.getEventsGlobal(this.slotId, deviceTimeZone);

            // ca trend data
            let lightCompCA = this.lightComp(
                datByCol[this.caI],
                datByCol[this.liI],
                props.caProperties.kl,
                props.caProperties.maxLight,
                []
            );
            const caTrendData = this.lowpassFilter(lightCompCA, props.caProperties.alpha);
            this.caTrendData = caTrendData;
            newDataArray.push({
                sensorName: "caTrend",
                history: caTrendData,
                label: "Chlorophyll Trend",
                units: "RFU",
            });
            // ca calibrated data
            if (cals.length > 0 && cals.find((x) => x.sensorName == "chlorA")) {
                const caRawData = newDataArray.find((x) => x.sensorName == "chlorA");
                const caCalData = cals.find((x) => x.sensorName == "chlorA");
                newDataArray.push({
                    sensorName: "caFieldCal",
                    history: calibrateHistory(caRawData.history, timeString, caCalData.moments),
                    label: "Field Calibrated Chlorophyll",
                    units: "ug/L",
                });
                newDataArray.push({
                    sensorName: "caTrendFieldCal",
                    history: calibrateHistory(caTrendData, timeString, caCalData.moments),
                    label: "Field Calibrated Chlorophyll Trend",
                    units: "ug/L",
                });
            } else {
                newDataArray.push({
                    sensorName: "caFieldCal",
                    history: [],
                    label: "Field Calibrated Chlorophyll",
                    units: "ug/L",
                });
                newDataArray.push({
                    sensorName: "caTrendFieldCal",
                    history: [],
                    label: "Field Calibrated Chlorophyll Trend",
                    units: "ug/L",
                });
            }
            //ca NPQ data
            // this.lightComp(this.datByCol[this.caI],this.datByCol[this.liI],this.playPC_k,this.playPC_maxLight,this.caAvgNightNPQ);
            const caNPQ = this.lightComp(
                this.datByCol[this.caI],
                this.datByCol[this.liI],
                props.caProperties.kl,
                props.caProperties.maxLight,
                this.caAvgNightNPQ
            );
            newDataArray.push({
                sensorName: "caNPQ",
                history: caNPQ,
                label: "Chlorophyll NPQ",
                units: "RFU",
            });

            // pc data
            let lightCompPC = this.lightComp(
                datByCol[this.pcI],
                datByCol[this.liI],
                props.pcProperties.kl,
                props.pcProperties.maxLight,
                []
            );
            const pcTrendData = this.lowpassFilter(lightCompPC, props.pcProperties.alpha);
            this.pcTrendData = pcTrendData;
            newDataArray.push({
                sensorName: "pcTrend",
                history: pcTrendData,
                label: "Phycocyanin Trend",
                units: "RFU",
            });
            // pc calibrated data
            if (cals.length > 0 && cals.find((x) => x.sensorName == "phycocyanin")) {
                const pcRawData = newDataArray.find((x) => x.sensorName == "phycocyanin");
                const pcCalData = cals.find((x) => x.sensorName == "phycocyanin");
                newDataArray.push({
                    sensorName: "pcFieldCal",
                    history: calibrateHistory(pcRawData.history, timeString, pcCalData.moments),
                    label: "Field Calibrated Phycocyanin",
                    units: "ug/L",
                });
                newDataArray.push({
                    sensorName: "pcTrendFieldCal",
                    history: calibrateHistory(pcTrendData, timeString, pcCalData.moments),
                    label: "Field Calibrated Phycocyanin Trend",
                    units: "ug/L",
                });
            } else {
                newDataArray.push({
                    sensorName: "pcFieldCal",
                    history: [],
                    label: "Field Calibrated Phycocyanin",
                    units: "ug/L",
                });
                newDataArray.push({
                    sensorName: "pcTrendFieldCal",
                    history: [],
                    label: "Field Calibrated Phycocyanin Trend",
                    units: "ug/L",
                });
            }
            // pc NPQ
            const pcNPQ = this.lightComp(
                this.datByCol[this.pcI],
                this.datByCol[this.liI],
                props.pcProperties.kl,
                props.pcProperties.maxLight,
                this.pcAvgNightNPQ
            );
            newDataArray.push({
                sensorName: "pcNPQ",
                history: pcNPQ,
                label: "Phycocyanin NPQ",
                units: "RFU",
            });

            // temperatue compensation PC
            const pcTempComp = this.tempComp(
                this.datByCol[this.pcI],
                props.pcProperties.alpha,
                props.pcProperties.stTemp,
                props.pcProperties.kTemp
            );
            newDataArray.push({
                sensorName: "pcTempComp",
                history: pcTempComp,
                label: "PC Temp. Comp.",
                units: "RFU",
            });
            // ratio pc / ca
            const algaeRatio = datByCol[this.pcI].map((val, ind) => {
                return val === 0 ? 0 : datByCol[this.caI][ind] / val
            });
            newDataArray.push({
                sensorName: "algaeRatio",
                history: algaeRatio,
                label: "CA/PC",
                units: "RFU",
            });

            // PC daily averages
            const dayAvgPC = this.dailyAverage(timeString, datByCol[this.pcI]);
            newDataArray.push({
                sensorName: "dayAvgPC",
                history: dayAvgPC,
                label: "Phycocyanin Daily Average",
                units: "RFU",
            });

            // CA daily averages
            const dayAvgCA = this.dailyAverage(timeString, datByCol[this.caI]);
            newDataArray.push({
                sensorName: "dayAvgCA",
                history: dayAvgCA,
                label: "Chlorophyll Daily Average",
                units: "RFU",
            });

            // TU daily averages
            const dayAvgTU = this.dailyAverage(timeString, datByCol[this.tuI]);
            newDataArray.push({
                sensorName: "dayAvgTU",
                history: dayAvgTU,
                label: "Turbidty Daily Average",
                units: "NTU",
            });

            // TU trend data
            const tuTrendData = this.tempCompTurbidity(
                this.datByCol[this.tuI],
                props.tuProperties.alphaTemp,
                props.tuProperties.stTemp,
                props.tuProperties.kTemp
            );
            this.tuTrendData = tuTrendData;
            newDataArray.push({
                sensorName: "tuTrend",
                history: tuTrendData,
                label: "Turbidity Trend",
                units: "NTU",
            });

            // temperatue compensation TU
            /*
            const tuTempComp = this.tempComp(this.datByCol[this.tuI],
              props.tuProperties.alphaTemp,props.tuProperties.stTemp,props.tuProperties.kTemp);
            newDataArray.push({
              sensorName:"tuTempComp",
              history: tuTempComp,
              label: "Turbidity Temperature Compensated",
              units: "NTU"
            });
            */
            // WT trend data
            const wtTrendData = this.lowpassFilter(
                newDataArray.find((x) => x.sensorName == "waterTemp").history,
                props.wtProperties.alpha
            );
            this.wtTrendData = wtTrendData;
            newDataArray.push({
                sensorName: "wtTrend",
                history: wtTrendData,
                label: "Water Temperature Trend",
                units: "C",
            });

            //WT farenheit
            newDataArray.push({
                sensorName: "wtF",
                history: newDataArray
                    .find((x) => x.sensorName == "waterTemp")
                    .history.flatMap((x) => x * (9 / 5) + 32),
                label: "Water Temperature",
                units: "F",
            });

            //WT trend farenheit
            newDataArray.push({
                sensorName: "wtFTrend",
                history: newDataArray
                    .find((x) => x.sensorName == "wtTrend")
                    .history.flatMap((x) => x * (9 / 5) + 32),
                label: "Water Temp. Trend",
                units: "F",
            });

            /* END CAL AND TREND ANALYSIS  */

            /* Gauges */
            const airTempFromServer = deviceSnapshot.deviceProps[this.atI].result;
            // let targetValue = 0;
            // this.statCharts = lastDataPoint.sensorGauges
            var gauges = this.sensorGauges;
            var smooth = this.smoothPlot;
            var degF = this.deg;
            var statCharts = gauges.map((x) => {
                const devicePropDetails = newDataArray.find((dp) => dp.sensorName === x.name);
                const len = devicePropDetails.history.length - 1;
                // let targetValue = fullDat[len][i];
                var targetValue = devicePropDetails.history[len];
                const alerts = this.device.deviceAlerts.find((y) => y.devicePropName == x.name);
                var high = alerts.high;
                var low = alerts.low;
                var maxValue = x.maxValue;
                if (devicePropDetails.sensorName === "airTemp") {
                    targetValue = airTempFromServer;
                    if (!this.deg) {
                        high = this.ctoF(high);
                        low = this.ctoF(low);
                        targetValue = this.ctoF(targetValue);
                        maxValue = this.ctoF(maxValue);
                        x.units = "\u00B0 F";
                    } else {
                        x.units = "\u00B0 C";
                    }
                }
                if (devicePropDetails.sensorName === "waterTemp") {
                    if (!degF) {
                        high = this.ctoF(high);
                        low = this.ctoF(low);
                        targetValue = this.ctoF(targetValue);
                        maxValue = this.ctoF(maxValue);
                        x.units = "\u00B0 F";
                    } else {
                        x.units = "\u00B0 C";
                    }
                }
                
                if (devicePropDetails.sensorName === "phycocyanin") {
                    if (smooth) {
                        targetValue = newDataArray.find((x) => x.sensorName == "pcTrend").history[len]; // TODO: show trend
                        x.units = "RFU (Trend)";
                    }
                    else {
                        x.units = "RFU";
                    }

                }
        
                if (devicePropDetails.sensorName === "chlorA") {
                    if (smooth) {
                        targetValue = newDataArray.find((x) => x.sensorName == "caTrend").history[len]; // TODO: show trend
                        x.units = "RFU (Trend)";
                    }
                    else {
                        x.units = "RFU";
                    }
                }

                return Object.assign(
                    {},
                    { displayName: x.displayName },
                    { sensorName: x.name },
                    { units: x.units },
                    { value: targetValue },
                    { labels: [x.name, "total"] },
                    {
                        datasets: [
                            {
                                label: x.name,
                                backgroundColor: [this.getColor(targetValue, high, low), "#eaeaea"],
                                data: [
                                    targetValue > maxValue ? maxValue : targetValue,
                                    maxValue > targetValue ? maxValue - targetValue : 0,
                                ],
                            },
                        ],
                    }
                );
            });
            this.statCharts = statCharts;
            // phycocyanin and chloraphyll chart
            const algaeTimeReportsChart = {
                labels: newDataArray.find((x) => x.sensorName == "timeString").history,
                datasets: [
                    {
                        // TODO: replace with compensated value
                        data: newDataArray.find((x) => x.sensorName == "phycocyanin").history, //pc
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "PC Detail",
                        backgroundColor: getLineChartColor("phycocyanin"),
                        borderColor: getLineChartColor("phycocyanin"),
                        borderDash: [2],
                        yAxisID: "y_axis_1",
                        hidden: !this.rawPlot,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "pcTrend").history, //pc lowpass
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "PC Trend",
                        backgroundColor: getLineChartColor("pcTrend"),
                        borderColor: getLineChartColor("pcTrend"),
                        borderWidth: 1,
                        yAxisID: "y_axis_1",
                        hidden: !this.smoothPlot,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "pcFieldCal").history, //pc field cal - may be empty
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "PC Cal",
                        backgroundColor: getLineChartColor("pcFieldCal"),
                        borderColor: getLineChartColor("pcFieldCal"),
                        borderWidth: 1,
                        yAxisID: "y_axis_1",
                        hidden: !this.fieldCalPlot,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "pcTrendFieldCal").history, //pc field cal - may be empty
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "PC Cal Trend",
                        backgroundColor: getLineChartColor("pcTrendFieldCal"),
                        borderColor: getLineChartColor("pcTrendFieldCal"),
                        borderWidth: 1,
                        yAxisID: "y_axis_1",
                        hidden: !this.fieldCalPlot,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "chlorA").history, //ca
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "CA Detail",
                        backgroundColor: getLineChartColor("chlorA"),
                        borderColor: getLineChartColor("chlorA"),
                        borderDash: [2],
                        yAxisID: "y_axis_2",
                        hidden: !this.rawPlot,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "caTrend").history, //ca lowpass
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "CA Trend",
                        backgroundColor: getLineChartColor("caTrend"),
                        borderColor: getLineChartColor("caTrend"),
                        borderWidth: 1,
                        yAxisID: "y_axis_2",
                        hidden: !this.smoothPlot,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "caFieldCal").history, //ca field calibration
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "CA Cal",
                        backgroundColor: getLineChartColor("caFieldCal"),
                        borderColor: getLineChartColor("caFieldCal"),
                        borderWidth: 1,
                        yAxisID: "y_axis_2",
                        hidden: !this.fieldCalPlot,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "caTrendFieldCal").history, //ca field calibration
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "CA Cal Trend",
                        backgroundColor: getLineChartColor("caTrendFieldCal"),
                        borderColor: getLineChartColor("caTrendFieldCal"),
                        borderWidth: 1,
                        yAxisID: "y_axis_2",
                        hidden: !this.fieldCalPlot,
                    },
                ],
            };
            this.algaeTimeReportsChart = algaeTimeReportsChart;

            const algaeLineChartOptions = getDefaultOptions();
            algaeLineChartOptions.plugins.title.text = "Phycocyanin and Chlorophyll-a";
            this.algaeLineChartOptions = algaeLineChartOptions;
            // water temp chart
            const waterTempLineChart = {
                labels: newDataArray.find((x) => x.sensorName == "timeString").history,
                datasets: [
                    {
                        data: newDataArray.find((x) => x.sensorName == "waterTemp").history, //pc
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "Water Temperature",
                        backgroundColor: getLineChartColor("waterTemp"),
                        borderColor: getLineChartColor("waterTemp"),
                        borderDash: [2],
                        yAxisID: "y_axis_1",
                        hidden: this.waterTrend,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "wtTrend").history, //pc lowpass
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "WT Trend",
                        backgroundColor: getLineChartColor("wtTrend"),
                        borderColor: getLineChartColor("wtTrend"),
                        borderWidth: 1,
                        yAxisID: "y_axis_1",
                        hidden: !this.waterTrend,
                    },
                ],
            };
            this.waterTempLineChart = waterTempLineChart;

            const waterTempOptions = getDefaultOptions();
            waterTempOptions.plugins.title.text = "Water Temperature";
            waterTempOptions.scales.y_axis_2.display = false;
            var minMaxT = [];
            if (!this.deg) {
                waterTempLineChart.datasets[0].data = newDataArray.find(
                    (x) => x.sensorName == "wtF"
                ).history;
                waterTempLineChart.datasets[1].data = newDataArray.find(
                    (x) => x.sensorName == "wtFTrend"
                ).history;
                waterTempOptions.scales.y_axis_1.scaleLabel.labelString = "Temp. (\u00B0 F)";
                minMaxT = getReportMinMax(
                    newDataArray.find((x) => x.sensorName == "wtF").history,
                    40,
                    60
                );
                // var a = this.timeReportsChartsList[this.wtI].datasets[0].data.map(function(e)
                //   {return ((e * (9/5)) + 32)});
                // this.timeReportsChartsList[this.wtI].datasets[0].data = a;
            } else {
                waterTempOptions.scales.y_axis_1.scaleLabel.labelString = "Temp. (\u00B0 C)";
                minMaxT = getReportMinMax(
                    newDataArray.find((x) => x.sensorName == "waterTemp").history,
                    -10,
                    10
                );
            }
            const wtAx = getAxesMinMaxStep(minMaxT[0], minMaxT[1], 10, 0);
            waterTempOptions.scales.y_axis_1.suggestedMin = wtAx[0];
            waterTempOptions.scales.y_axis_1.suggestedMax = wtAx[1];
            waterTempOptions.scales.y_axis_1.ticks.stepSize = wtAx[2];
            this.waterTempOptions = waterTempOptions;

            // turbidity chart
            const turbidityLineChart = {
                labels: newDataArray.find((x) => x.sensorName == "timeString").history,
                datasets: [
                    {
                        data: newDataArray.find((x) => x.sensorName == "turbidity").history, //pc
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "Turbidity",
                        backgroundColor: getLineChartColor("turbidity"),
                        borderColor: getLineChartColor("turbidity"),
                        borderDash: [2],
                        yAxisID: "y_axis_1",
                        hidden: this.turbidityTrend,
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "tuTrend").history, //pc lowpass
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "Turbidity Trend",
                        backgroundColor: getLineChartColor("tuTrend"),
                        borderColor: getLineChartColor("tuTrend"),
                        borderWidth: 1,
                        yAxisID: "y_axis_1",
                        hidden: !this.turbidityTrend,
                    },
                ],
            };
            this.turbidityLineChart = turbidityLineChart;

            const turbidityOptions = getDefaultOptions();
            turbidityOptions.plugins.title.text = "Turbidity";
            turbidityOptions.scales.y_axis_2.display = false;
            turbidityOptions.scales.y_axis_1.scaleLabel.labelString = "Turbidity (NTU)";
            var minMaxTU = getReportMinMax(
                newDataArray.find((x) => x.sensorName == "turbidity").history,
                0,
                10
            );
            const tuAx = getAxesMinMaxStep(minMaxTU[0], minMaxTU[1], 10, 1);
            turbidityOptions.scales.y_axis_1.suggestedMax = tuAx[1];
            turbidityOptions.scales.y_axis_1.suggestedMin = tuAx[0];
            turbidityOptions.scales.y_axis_1.ticks.stepSize = tuAx[2];
            this.turbidityOptions = turbidityOptions;

            this.updateAnnotations();
            this.caPcChart(false);

            // battery and panel volts chart
            const battPVChart = {
                labels: newDataArray.find((x) => x.sensorName == "timeString").history,
                datasets: [
                    {
                        data: newDataArray.find((x) => x.sensorName == "battSOC").history, //batSOC
                        // time: datByCol[this.utI],
                        // dataFullObj: report.timeReports[firstIndex].map((tr) => tr),
                        fill: false,
                        label: "Battery Strength",
                        backgroundColor: getLineChartColor("battSOC"),
                        borderColor: getLineChartColor("battSOC"),
                        borderWidth: 1,
                        yAxisID: "y_axis_1",
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "panelVolts").history, //panelVolts
                        // time: datByCol[this.utI],
                        // dataFullObj: report.timeReports[secondIndex].map((tr) => tr),
                        fill: false,
                        label: "Panel Volts",
                        backgroundColor: getLineChartColor("panelVolts"),
                        borderColor: getLineChartColor("panelVolts"),
                        borderWidth: 1,
                        yAxisID: "y_axis_2",
                    },
                ],
            };
            this.battPVChart = battPVChart;
            const battPVOptions = getDefaultOptions();
            battPVOptions.scales.y_axis_2.max = 22;
            battPVOptions.scales.y_axis_2.ticks.stepSize = Math.round(22 / 10);
            battPVOptions.scales.y_axis_2.scaleLabel.labelString = "Solar Panel (V)";
            battPVOptions.scales.y_axis_1.scaleLabel.labelString = "Battery Strength (%)";
            battPVOptions.plugins.title.text = "Battery and Solar Panel";
            this.battPVOptions = battPVOptions;

            // signal strength and solar light chart
            const ssLiChart = {
                labels: newDataArray.find((x) => x.sensorName == "timeString").history,
                datasets: [
                    {
                        data: newDataArray.find((x) => x.sensorName == "sigStrength").history, //sigstrength
                        // time: datByCol[this.utI],
                        fill: false,
                        label: "Signal Strength",
                        backgroundColor: getLineChartColor("sigStrength"),
                        borderColor: getLineChartColor("sigStrength"),
                        borderWidth: 1,
                        yAxisID: "y_axis_1",
                    },
                    {
                        data: newDataArray.find((x) => x.sensorName == "light").history, //solarLight
                        // time: datByCol[this.utI],
                        // dataFullObj: report.timeReports[secondIndex].map((tr) => tr),
                        fill: false,
                        label: "Solar Light",
                        backgroundColor: getLineChartColor("light"),
                        borderColor: getLineChartColor("light"),
                        borderWidth: 1,
                        yAxisID: "y_axis_2",
                    },
                ],
            };
            this.ssLiChart = ssLiChart;
            const sigStrengthSolarOptions = getDefaultOptions();
            const minMaxSS = getReportMinMax(
                newDataArray.find((x) => x.sensorName == "sigStrength").history,
                0,
                20
            );
            const ssAx = getAxesMinMaxStep(minMaxSS[0], minMaxSS[1], 10, 1);
            sigStrengthSolarOptions.scales.y_axis_1.max = ssAx[1];
            sigStrengthSolarOptions.scales.y_axis_1.ticks.stepSize = ssAx[2];
            sigStrengthSolarOptions.scales.y_axis_1.scaleLabel.labelString = "Signal Strength (%)";

            const minMaxLi = getReportMinMax(
                newDataArray.find((x) => x.sensorName == "light").history,
                0,
                400
            );
            const liAx = getAxesMinMaxStep(minMaxLi[0], minMaxLi[1], 10, 1);
            sigStrengthSolarOptions.scales.y_axis_2.max = liAx[1];
            sigStrengthSolarOptions.scales.y_axis_2.ticks.stepSize = liAx[2];
            sigStrengthSolarOptions.scales.y_axis_2.scaleLabel.labelString = "Solar Light (PAR)";
            sigStrengthSolarOptions.plugins.title.text = "Signal Strength and Ambient Solar Light";
            this.sigStrengthSolarOptions = sigStrengthSolarOptions;

            //update homepage data
            // console.log("break");
            let time = dayjs.utc(fullDat[lenDat - 1][this.utI] * 1000)
                .local()
                .format("MM-DD HH:mm:ss a");
            let iHomePage = this.outDat.findIndex((x) => x.id == this.slotId);
            this.outDat[iHomePage].bat = fullDat[lenDat - 1][this.bsI].toFixed(0);
            this.outDat[iHomePage].sig = fullDat[lenDat - 1][this.ssI].toFixed(0);
            this.outDat[iHomePage].sig = fullDat[lenDat - 1][this.ssI].toFixed(0);
            this.outDat[iHomePage].lastHeard = this.getLastHeard(this.slotId);
            this.outDat[iHomePage].online = true;
            //this.outDat[iHomePage].lastTEMP = fullDat[lenDat - 1][this.atI].toFixed(0);
            this.outDat[iHomePage].lastPC = fullDat[lenDat - 1][this.pcI].toFixed(0);
            this.outDat[iHomePage].lastCA = fullDat[lenDat - 1][this.caI].toFixed(0);
            this.outDat[iHomePage].lastTB = fullDat[lenDat - 1][this.tuI].toFixed(0);
            this.outDat[iHomePage].latLong = {
                lat: parseFloat(this.deviceLat),
                lng: parseFloat(this.deviceLong),
            };
            // console.log("break");

            this.newDataArray = newDataArray;
            this.createCustomChart();
            this.createPlayground();
        },
        async getReports(slotId) {
            this.loaded = false;
            this.isLoading = true;
            this.timeSeriesNames = [];
            this.slotId = slotId;
            this.resetCharts();
            const slotStore = useSlotStore();
            this.device = slotStore.currentSlot;
            this.trendlineProperties = this.device.trendlineProperties;
            // this.getSlotData();
            try {
                /* Querry Data */
                const payload = {
                    slotId: slotId,
                    startDate: new Date(this.startDate).toISOString(),
                    endDate: new Date(this.endDate).toISOString(),
                };

                await slotStore.fetchDeviceSnapshot();
                await slotStore.fetchDeviceHistory(payload);

                const deviceHistory = slotStore.computeCompensatedHistory();

                /* If data exists */
                if (deviceHistory && deviceHistory.sensors && deviceHistory.sensors[0].history.length > 0) {
                    await this.displayDeviceHistoryImpl(deviceHistory, slotStore);
                } else {
                    this.lastHeard = "Device Offline";

                    this.signal = 0;
                    this.battery = 0;

                    // this.statCharts = {};
                    this.blankStatChart();
                    const blankChart = {
                        labels: [],
                        datasets: [
                            {
                                data: [],
                                time: [],
                                label: "",
                                borderWidth: 1,
                            },
                        ],
                    };
                    this.algaeTimeReportsChart = blankChart;
                    this.signalAndPanelTimeReportsChart = blankChart;
                    this.ssLiChart = blankChart;
                    this.battPVChart = blankChart;
                    this.customChart = blankChart;
                    this.waterTempReportsChart = blankChart;
                    this.turbidityReportsChart = blankChart;
                    this.timeReportsChartsList = [];
                    this.playgroundChart = blankChart;
                    this.deviceLat = this.device.gpsLat;
                    this.deviceLong = this.device.gpsLong;
                    this.deviceInWater = this.device.inWater;
                    /* NOW do time zone, weather */
                    var updatedPayload = {
                        lat: this.deviceLat,
                        lng: this.deviceLong,
                    };

                    await slotStore.fetchWeatherLabel(updatedPayload);
                    await slotStore.fetchTimeZone(updatedPayload);
                    this.deviceOffline = true;
                }
            } catch (error) {
                console.log("getReports error", error);
            }
            this.incrementKeys();
            this.$forceUpdate();
            this.allowDetailPage = true;
            this.loaded = true;
            this.isLoading = false;
        },
        // Return 0: normal; 1: warning. 2: error
        computeAlertColor(currentValue, high, low) {
            if (currentValue > high || currentValue < low) {
                return 2;
            }

            const delta = high - low;
            const rangeRatio = 0.2; // 20%
            // const lowWarning = userAlertLow + delta * rangeRatio;
            const highWarning = high - delta * rangeRatio;
            if (currentValue > low && currentValue < highWarning) {
                return 0;
            } else {
                return 1;
            }
        },
        getColor(value, high, low) {
            let color = "#8DC050";
            const green = "#8DC050";
            const orange = "#ffa600";
            const red = "#FF0000";
            const computedColor = this.computeAlertColor(value, high, low);
            switch (computedColor) {
                case 0:
                    color = green;
                    break;
                case 1:
                    color = orange;
                    break;
                case 2:
                    color = red;
                    break;
                default:
                    break;
            }
            return color;
        },
        getAnnotationColor(name) {
            console.log(name);
            let color = "rgba(193, 120, 245, 0.6)";
            switch (name) {
                case "Rain":
                    color = "rgba(117, 223, 255, 0.6)";
                    break;
                case "Treatment":
                    color = "rgba(193, 120, 245, 0.6)";
                    break;

                case "Rain_bold":
                    color = "rgba(117, 223, 255, 1)";
                    break;

                case "Treatment_bold":
                    color = "rgba(193, 120, 245, 1)";
                    break;
                default:
                    break;
            }
            return color;
        },
        getLineChartUnits(displayName) {
            let options;
            switch (true) {
                case displayName === "Water Temperature":
                    options = this.waterTempOptions;
                    break;
                case displayName === "Solar Light":
                    options = this.lightOptions;
                    break;
                case displayName === "turbidity":
                    options = this.turbidityOptions;
                    break;
                default:
                    break;
            }
            return options;
        },
        async createCustomChart() {
            ////////////////////////
            // Save options
            const preferenceStore = usePreferenceStore();
            const patchBody = {
                phycocyaninSelected: this.sensorsSelected.find(item => item.sensor === 'phycocyanin').selected,
                chlorASelected: this.sensorsSelected.find(item => item.sensor === 'chlorA').selected,
                waterTempSelected: this.sensorsSelected.find(item => item.sensor === 'waterTemp').selected,
                phSelected: this.sensorsSelected.find(item => item.sensor === 'ph').selected,
                doSelected: this.sensorsSelected.find(item => item.sensor === 'do').selected,
                psSelected: this.sensorsSelected.find(item => item.sensor === 'ps').selected,
                turbiditySelected: this.sensorsSelected.find(item => item.sensor === 'turbidity').selected,
                lightSelected: this.sensorsSelected.find(item => item.sensor === 'light').selected,
                battSOCSelected: this.sensorsSelected.find(item => item.sensor === 'battSOC').selected,
                sigStrengthSelected: this.sensorsSelected.find(item => item.sensor === 'sigStrength').selected,
                panelVoltsSelected: this.sensorsSelected.find(item => item.sensor === 'panelVolts').selected,
                algaeRatioSelected: this.sensorsSelected.find(item => item.sensor === 'algaeRatio').selected,
                dayAvgPCSelected: this.sensorsSelected.find(item => item.sensor === 'dayAvgPC').selected,
                dayAvgCASelected: this.sensorsSelected.find(item => item.sensor === 'dayAvgCA').selected,
                dayAvgTUSelected: this.sensorsSelected.find(item => item.sensor === 'dayAvgTU').selected
            }

            await preferenceStore.updatePreference(patchBody);
            ////////////////////////
            const sensorsSelected = this.sensorsSelected;
            var customChartOptions = getDefaultOptions();
            customChartOptions.plugins.title.text = "Custom Chart";
            let data = [];
            let yaxis = "y_axis_1";
            let leftMin = 0;
            let leftMax = 1;
            let rightMin = 0;
            let rightMax = 1;

            for (let i = 0; i < sensorsSelected.length; i++) {
                if (sensorsSelected[i].selected && this.featureEnabled(sensorsSelected[i].feature)) {
                    var dat = this.newDataArray.find((x) => x.sensorName == sensorsSelected[i].sensor)
                        .history;
                    let newMinMax = getReportMinMax(dat, 0, 1);
                    if (sensorsSelected[i].axis == "right") {
                        yaxis = "y_axis_2";
                        if (rightMin > newMinMax[0]) {
                            rightMin = newMinMax[0];
                        }
                        if (rightMax < newMinMax[1]) {
                            rightMax = newMinMax[1];
                        }
                        customChartOptions.scales.y_axis_2.suggestedMax = rightMax;
                        customChartOptions.scales.y_axis_2.suggestedMin = rightMin;
                        customChartOptions.scales.y_axis_2.ticks.stepSize = Math.round(
                            Math.max(rightMin, rightMax) / 10
                        );
                    } else {
                        yaxis = "y_axis_1";
                        if (leftMin > newMinMax[0]) {
                            leftMin = newMinMax[0];
                        }
                        if (leftMax < newMinMax[1]) {
                            leftMax = newMinMax[1];
                        }
                        customChartOptions.scales.y_axis_1.suggestedMax = leftMax;
                        customChartOptions.scales.y_axis_1.suggestedMin = leftMin;
                        customChartOptions.scales.y_axis_1.ticks.stepSize = Math.round(
                            Math.max(leftMin, leftMax) / 10
                        );
                    }
                    data.push({
                        data: dat,
                        fill: false,
                        label: this.newDataArray.find((x) => x.sensorName == sensorsSelected[i].sensor).label,
                        backgroundColor: getLineChartColor(sensorsSelected[i].sensor),
                        borderColor: getLineChartColor(sensorsSelected[i].sensor),
                        borderWidth: 1,
                        yAxisID: yaxis,
                    });
                }
            }
            let customChart = {
                labels: this.newDataArray.find((x) => x.sensorName == "timeString").history,
                datasets: [...data],
            };
            this.customChart = customChart;
            this.customChartOptions = customChartOptions;
            this.keyCustom += 1;
            return;
        },
        createPlayground() {
            this.isLoading = true;
            this.updatePlayChart();
            const data = this.newDataArray;
            this.playgroundChart = null;
            this.playgroundChartOptions = null;
            var options = getDefaultOptions();
            options.plugins.title.text = "Playground Chart";
            const params = [
                { sensorName: "phycocyanin", model: this.playPC, axes: this.playPCAx },
                { sensorName: "pcTrend", model: this.playPCTrend, axes: this.playPCAx },
                { sensorName: "pcNPQ", model: this.playPCNPQ, axes: this.playPCAx },
                { sensorName: "pcTempComp", model: this.playPCTempComp, axes: this.playPCAx },
                { sensorName: "dayAvgPC", model: this.playPCAvg, axes: this.playPCAx },
                { sensorName: "chlorA", model: this.playCA, axes: this.playCAAx },
                { sensorName: "caTrend", model: this.playCATrend, axes: this.playCAAx },
                { sensorName: "caNPQ", model: this.playCANPQ, axes: this.playCAAx },
                { sensorName: "dayAvgCA", model: this.playCAAvg, axes: this.playCAAx },
                { sensorName: "turbidity", model: this.playTU, axes: this.playTUAx },
                { sensorName: "tuTrend", model: this.playTUTrend, axes: this.playTUAx },
                { sensorName: "dayAvgTU", model: this.playTUAvg, axes: this.playTUAx },
                { sensorName: "waterTemp", model: this.playWT, axes: this.playWTAx },
                { sensorName: "wtTrend", model: this.playWTTrend, axes: this.playWTAx },
                { sensorName: "light", model: this.playSL, axes: this.playSLAx },
                { sensorName: "panelVolts", model: this.playPV, axes: this.playPVAx },
                { sensorName: "sigStrength", model: this.playSS, axes: this.playSSAx },
                { sensorName: "battSOC", model: this.playBS, axes: this.playBSAx },
                { sensorName: "leakDetect", model: this.playLD, axes: this.playLDAx },
            ];
            let yaxis = "y_axis_1";
            let leftMin = 0;
            let leftMax = 20;
            let rightMin = 0;
            let rightMax = 20;
            var datasets = [];

            for (let i = 0; i < params.length; i++) {
                if (params[i].model) {
                    let tmpDat = [];
                    let newMinMax = [];
                    let color = "";
                    let ylabel = "";
                    tmpDat = data.find((x) => x.sensorName == params[i].sensorName);
                    if (
                        (params[i].sensorName == "waterTemp" || params[i].sensorName == "wtTrend") &&
                        !this.unitPlayTemp
                    ) {
                        if (params[i].sensorName == "waterTemp") {
                            tmpDat = data.find((x) => x.sensorName == "wtF");
                        } else {
                            tmpDat = data.find((x) => x.sensorName == "wtFTrend");
                        }
                    }
                    color = getLineChartColor(params[i].sensorName);
                    newMinMax = getReportMinMax(tmpDat.history, 0, 20);
                    ylabel = tmpDat.label + " (" + tmpDat.units + ")";
                    if (params[i].axes) {
                        yaxis = "y_axis_2";
                        if (rightMin > newMinMax[0]) {
                            rightMin = newMinMax[0];
                        }
                        if (rightMax < newMinMax[1]) {
                            rightMax = newMinMax[1];
                        }
                        options.scales.y_axis_2.suggestedMax = rightMax;
                        options.scales.y_axis_2.suggestedMin = rightMin;
                        options.scales.y_axis_2.ticks.stepSize = Math.max(rightMin, rightMax) / 10;
                        options.scales.y_axis_2.scaleLabel.labelString = ylabel;
                    } else {
                        yaxis = "y_axis_1";
                        if (leftMin > newMinMax[0]) {
                            leftMin = newMinMax[0];
                        }
                        if (leftMax < newMinMax[1]) {
                            leftMax = newMinMax[1];
                        }
                        options.scales.y_axis_1.suggestedMax = leftMax;
                        options.scales.y_axis_1.suggestedMin = leftMin;
                        options.scales.y_axis_1.ticks.stepSize = Math.max(leftMin, leftMax) / 10;
                        options.scales.y_axis_1.scaleLabel.labelString = ylabel;
                    }
                    datasets.push({
                        data: tmpDat.history,
                        fill: false,
                        label: params[i].sensorName,
                        backgroundColor: color,
                        borderColor: color,
                        borderWidth: 1,
                        yAxisID: yaxis,
                    });
                }
            }
            var labels = data.find((x) => x.sensorName == "timeString").history;
            let createPlayChart = {
                labels: [...labels],
                datasets: [...datasets],
            };
            this.playgroundChart = createPlayChart;
            this.playgroundChartOptions = options;
            this.keyPlayground += 1;
            this.isLoading = false;
            return;
        },
        updatePlayChart() {
            var props = this.trendlineProperties;
            var updated = this.newDataArray;
            if (this.playPCTrend) {
                var pc_light = this.lightComp(
                    this.datByCol[this.pcI],
                    this.datByCol[this.liI],
                    props.pcProperties.kl,
                    props.pcProperties.maxLight,
                    []
                );
                var pcTrendData = this.lowpassFilter(pc_light, props.pcProperties.alpha);
                updated[updated.findIndex((x) => x.sensorName == "pcTrend")].history = pcTrendData;
            }
            if (this.playPCNPQ) {
                var pc_light1 = this.lightComp(
                    this.datByCol[this.pcI],
                    this.datByCol[this.liI],
                    props.pcProperties.kl,
                    props.pcProperties.maxLight,
                    this.pcAvgNightNPQ
                );
                var pcNPQTrendData = this.lowpassFilter(pc_light1, props.pcProperties.alpha);
                updated[updated.findIndex((x) => x.sensorName == "pcNPQ")].history = pcNPQTrendData;
            }
            if (this.playPCTempComp) {
                var pcTempCompData = this.tempComp(
                    this.datByCol[this.pcI],
                    props.pcProperties.alphaTemp,
                    props.pcProperties.stTemp,
                    props.pcProperties.kTemp
                );
                updated[updated.findIndex((x) => x.sensorName == "pcTempComp")].history = pcTempCompData;
            }
            if (this.playCATrend) {
                var ca_light = this.lightComp(
                    this.datByCol[this.caI],
                    this.datByCol[this.liI],
                    props.caProperties.kl,
                    props.caProperties.maxLight,
                    []
                );
                var caTrendData = this.lowpassFilter(ca_light, props.caProperties.alpha);
                updated[updated.findIndex((x) => x.sensorName == "caTrend")].history = caTrendData;
            }
            if (this.playCANPQ) {
                var ca_light1 = this.lightComp(
                    this.datByCol[this.caI],
                    this.datByCol[this.liI],
                    props.caProperties.kl,
                    props.caProperties.maxLight,
                    this.caAvgNightNPQ
                );
                var caNPQTrendData = this.lowpassFilter(ca_light1, props.caProperties.alpha);
                updated[updated.findIndex((x) => x.sensorName == "caNPQ")].history = caNPQTrendData;
            }
            if (this.playTUTrend) {
                var tuTrendData = this.tempCompTurbidity(
                    this.datByCol[this.tuI],
                    props.tuProperties.alphaTemp,
                    props.tuProperties.stTemp,
                    props.tuProperties.kTemp
                );
                updated[updated.findIndex((x) => x.sensorName == "tuTrend")].history = tuTrendData;
            }
            if (this.playWTTrend) {
                var waterTrendData = this.lowpassFilter(this.datByCol[this.wtI], props.wtProperties.alpha);
                updated[updated.findIndex((x) => x.sensorName == "wtTrend")].history = waterTrendData;
            }
            this.newDataArray = updated;
            this.trendlineProperties = props;
            return;
        },
        uniq(a) {
            var seen = {};
            return a.filter(function (item) {
                return seen.hasOwnProperty(item) ? false : (seen[item] = true);
            });
        },
        dailyAverage(timeStringArray, sensorArray) {
            var avgs = [];
            var outDat = [];
            var dates = this.uniq(timeStringArray.map((x) => new Date(x).getDate()));
            for (var i = 0; i < dates.length; i++) {
                var current = [];
                timeStringArray.forEach(function (x, ind) {
                    if (new Date(x).getDate() == dates[i]) {
                        current.push(sensorArray[ind]);
                    }
                });
                avgs.push(current.reduce((a, b) => a + b) / current.length);
            }
            for (var c = 0; c < dates.length; c++) {
                timeStringArray.forEach(function (x) {
                    if (new Date(x).getDate() == dates[c]) {
                        outDat.push(avgs[c]);
                    }
                });
            }
            return outDat;
        },
        ftoC(val) {
            return (val - 32) * (5 / 9);
        },
        ctoF(val) {
            return val * (9 / 5) + 32;
        },
        tempComp(sensorData, alpha, temperature, k) {
            // calVal is the sensor high calibration value
            // do temperature comp
            k = parseFloat(k);
            temperature = parseFloat(temperature);
            // let waterTemp = this.datByCol[this.wtI];
            let waterTemp = this.newDataArray.find((x) => x.sensorName == "waterTemp").history;

            let outDat = [];
            // if(this.deg || !this.unitPlayTemp)
            // { // if temp is in f, change to c
            //  waterTemp = waterTemp.map((x)=> this.ftoC(x));
            // }
            let tempCmpDat = [];
            for (let i = 0; i < waterTemp.length; i++) {
                // if (usecalVal)
                // {
                //   tempCmpDat[i] = sensorData[i] + ((sensorData[i]/calVal) * k * (waterTemp[i] - temperature));
                // }
                // else
                // {
                tempCmpDat[i] = sensorData[i] + k * (temperature - waterTemp[i]);
                // }
            }
            // now do lowpass filter
            outDat = this.lowpassFilter(tempCmpDat, alpha);
            return outDat;
        },
        tempCompTurbidity(sensorData, alpha, temperature, k) {
            // calVal is the sensor high calibration value
            // do temperature comp
            k = parseFloat(k);
            temperature = parseFloat(temperature);
            // let waterTemp = this.datByCol[this.wtI];
            let waterTemp = this.newDataArray.find((x) => x.sensorName == "waterTemp").history;

            let outDat = [];
            // if(this.deg || !this.unitPlayTemp)
            // { // if temp is in f, change to c
            //  waterTemp = waterTemp.map((x)=> this.ftoC(x));
            // }
            let tempCmpDat = [];
            for (let i = 0; i < waterTemp.length; i++) {
                // if (usecalVal)
                // {
                //   tempCmpDat[i] = sensorData[i] + ((sensorData[i]/calVal) * k * (waterTemp[i] - temperature));
                // }
                // else
                // {
                tempCmpDat[i] = sensorData[i] + (k / 100) * sensorData[i] * (waterTemp[i] - temperature);
                // }
            }
            // now do lowpass filter
            outDat = this.lowpassFilter(tempCmpDat, alpha);
            return outDat;
        },
        NPQComp(fullDat) {
            // calculate average of prev nights CA and PC
            let nightDatCA = []; // temp storage of night time data
            let nightDatPC = [];
            let dayDatCA = [];
            let dayDatPC = [];
            let pcAvgNightNPQ = []; // new array to hold prev nights data
            let caAvgNightNPQ = [];
            let tCANight = 0;
            let tPCNight = 0;
            let tCADay = 0;
            let tPCDay = 0;
            let tCA = 0;
            let tPC = 0;
            let c = 0;
            let c2 = 0;
            let isnight = false;
            let isday = false;
            let nightFull = false;
            let dayFull = false;
            let minDate = 0;
            let newDat = [];

            // get total time (in hours) of data in fullDat
            let totalTime =
                Math.abs(new Date(fullDat[0][0] * 1000) - new Date(fullDat[fullDat.length - 1][0] * 1000)) /
                36e5;
            // find first instance of day and night hours
            let dayStart = fullDat.findIndex(
                (x) => new Date(x[0] * 1000).getHours() >= 10 && new Date(x[0] * 1000).getHours() <= 12
            );
            let dayEnd = fullDat.findIndex(
                (x) => new Date(x[0] * 1000).getHours() >= 14 && new Date(x[0] * 1000).getHours() <= 16
            );
            let nightStart = fullDat.findIndex(
                (x) => new Date(x[0] * 1000).getHours() >= 22 && new Date(x[0] * 1000).getHours() <= 24
            );
            let nightEnd = fullDat.findIndex(
                (x) => new Date(x[0] * 1000).getHours() >= 2 && new Date(x[0] * 1000).getHours() <= 4
            );
            if (dayStart <= 0 || nightStart <= 0 || dayEnd <= 0 || nightEnd <= 0 || totalTime <= 48) {
                for (let i = 0; i < fullDat.length; i++) {
                    caAvgNightNPQ[i] = fullDat[i][this.caI];
                    pcAvgNightNPQ[i] = fullDat[i][this.pcI];
                }
                newDat = fullDat;
            } else {
                if (nightEnd < dayEnd) {
                    minDate = new Date(fullDat[Math.max(dayEnd, nightEnd)][0] * 1000).getDate() + 1;
                } else {
                    minDate = new Date(fullDat[Math.max(dayEnd, nightEnd)][0] * 1000).getDate();
                }
                if (nightEnd < nightStart) {
                    let b = fullDat.slice(nightStart);
                    let newNight = b.findIndex(
                        (x) => new Date(x[0] * 1000).getHours() >= 2 && new Date(x[0] * 1000).getHours() <= 4
                    );
                    nightEnd = newNight + nightStart;
                }
                if (dayEnd < dayStart) {
                    let b = fullDat.slice(dayStart);
                    let newDay = b.findIndex(
                        (x) => new Date(x[0] * 1000).getHours() >= 14 && new Date(x[0] * 1000).getHours() <= 16
                    );
                    dayEnd = newDay + dayStart;
                }
                // get data by time
                let day1 = fullDat.slice(dayStart, dayEnd);
                let avgCaDay1 = day1.map((x) => x[this.caI]).reduce((a, b) => a + b, 0) / day1.length;
                let avgPcDay1 = day1.map((x) => x[this.pcI]).reduce((a, b) => a + b, 0) / day1.length;

                let night1 = fullDat.slice(nightStart, nightEnd);
                let avgCaNight1 = night1.map((x) => x[this.caI]).reduce((a, b) => a + b, 0) / night1.length;
                let avgPcNight1 = night1.map((x) => x[this.pcI]).reduce((a, b) => a + b, 0) / night1.length;

                tCA = avgCaNight1 / avgCaDay1;
                tPC = avgPcNight1 / avgPcDay1;

                // reduce data
                newDat = fullDat.slice(
                    fullDat.findIndex((x) => new Date(x[0] * 1000).getDate() >= minDate)
                );

                // now perform NPQ
                for (let i = 0; i < newDat.length; i++) {
                    let tmp = new Date(newDat[i][this.utI] * 1000).getHours();
                    // console.log(tmp);
                    if (tmp >= 22 || tmp < 3) {
                        nightDatCA[c] = newDat[i][this.caI];
                        nightDatPC[c] = newDat[i][this.pcI];
                        isnight = true; // switch store dat
                        c += 1;
                    } else if (tmp >= 3 && tmp < 10) {
                        isnight = false;
                    } else if (tmp >= 10 && tmp < 15) {
                        dayDatCA[c2] = newDat[i][this.caI];
                        dayDatPC[c2] = newDat[i][this.pcI];
                        isday = true; // switch store dat
                        c2 += 1;
                    } else if (tmp >= 15 && tmp < 22) {
                        isday = false;
                    } else {
                        // console.log("Error on day/night switch, time not accounted for");
                        // console.log(tmp);
                    }
                    if (!isnight && c > 0) {
                        // take avg
                        tCANight = nightDatCA.reduce((a, b) => a + b, 0) / nightDatCA.length;
                        tPCNight = nightDatPC.reduce((a, b) => a + b, 0) / nightDatPC.length;
                        nightDatCA = [];
                        nightDatPC = [];
                        c = 0;
                        nightFull = true;
                    }
                    if (!isday && c2 > 0) {
                        tCADay = dayDatCA.reduce((a, b) => a + b, 0) / dayDatCA.length;
                        tPCDay = dayDatPC.reduce((a, b) => a + b, 0) / dayDatPC.length;
                        dayDatCA = [];
                        dayDatPC = [];
                        c2 = 0;
                        dayFull = true;
                    }
                    if (dayFull && nightFull) {
                        tCA = tCANight / tCADay;
                        tPC = tPCNight / tPCDay;
                        dayFull = false;
                        nightFull = false;
                    }

                    caAvgNightNPQ[i] = tCA;
                    pcAvgNightNPQ[i] = tPC;
                }
            }

            this.pcAvgNightNPQ = pcAvgNightNPQ;
            this.caAvgNightNPQ = caAvgNightNPQ;
            return newDat;
        },
        lightComp(param, lightDat, k, maxLight, nightAvg) {
            k = parseFloat(k);
            maxLight = parseFloat(maxLight);
            let outDat = [];
            for (let i = 0; i < param.length; i++) {
                if (i == 0) {
                    if (nightAvg.length == param.length) {
                        outDat[i] = param[i] + k * nightAvg[i] * Math.min(lightDat[i], maxLight);
                    } else {
                        outDat[i] = param[i] + k * Math.min(lightDat[i], maxLight);
                    }
                } else {
                    if (nightAvg.length == param.length) {
                        outDat[i] = param[i] + k * nightAvg[i] * Math.min(lightDat[i - 1], maxLight);
                    } else {
                        outDat[i] = param[i] + k * Math.min(lightDat[i - 1], maxLight);
                    }
                }
            }
            return outDat;
        },
        lowpassFilter(param, alpha) {
            alpha = parseFloat(alpha);
            let outDat = [];
            // if (alpha == 0)
            // {
            //   outDat = param;
            // }
            // else
            // {
            for (let i = 0; i < param.length; i++) {
                if (i == 0) {
                    outDat[i] = param[i];
                } else {
                    outDat[i] = (1 - alpha) * param[i] + alpha * outDat[i - 1];
                }
            }
            // }
            return outDat;
        },
        assignSensorIndices(hisReport) {
            this.utI = hisReport.sensors.findIndex((x) => x.sensorName == "utcTime");
            this.wtI = hisReport.sensors.findIndex((x) => x.sensorName == "waterTemp");
            this.atI = hisReport.sensors.findIndex((x) => x.sensorName == "airTemp");
            this.liI = hisReport.sensors.findIndex((x) => x.sensorName == "light");
            this.pcI = hisReport.sensors.findIndex((x) => x.sensorName == "phycocyanin");
            this.caI = hisReport.sensors.findIndex((x) => x.sensorName == "chlorA");
            this.tuI = hisReport.sensors.findIndex((x) => x.sensorName == "turbidity");
            this.bsI = hisReport.sensors.findIndex((x) => x.sensorName == "battSOC");
            this.ssI = hisReport.sensors.findIndex((x) => x.sensorName == "sigStrength");
            this.utI = hisReport.sensors.findIndex((x) => x.sensorName == "utcTime");
            this.pvI = hisReport.sensors.findIndex((x) => x.sensorName == "panelVolts");
            this.wlI = hisReport.sensors.findIndex((x) => x.sensorName == "waterLight");
            this.btI = hisReport.sensors.findIndex((x) => x.sensorName == "buoyTemp");
            this.laI = hisReport.sensors.findIndex((x) => x.sensorName == "latitude");
            this.loI = hisReport.sensors.findIndex((x) => x.sensorName == "longitude");
            this.bvI = hisReport.sensors.findIndex((x) => x.sensorName == "battVolts");
            this.sqI = hisReport.sensors.findIndex((x) => x.sensorName == "sigQuality");
            this.cvI = hisReport.sensors.findIndex((x) => x.sensorName == "codeVersion");
            this.ldI = hisReport.sensors.findIndex((x) => x.sensorName == "leakDetect");
            return;
        },
        assignChartTitles(sensor) {
            let label = "";
            let units = "";
            switch (true) {
                case sensor === "chlorA":
                    label = "Chlorophyll-a";
                    units = "RFU";
                    break;
                case sensor === "phycocyanin":
                    label = "Phycocyanin";
                    units = "RFU";
                    break;
                case sensor === "airTemp":
                    label = "Air Temperature";
                    units = "C";
                    break;
                case sensor === "waterTemp":
                    label = "Water Temperature";
                    units = "C";
                    break;
                case sensor === "light":
                    label = "Ambient Solar Light";
                    units = "uE/m²/sec";
                    break;
                case sensor === "turbidity":
                    label = "Turbidity";
                    units = "NTU";
                    break;
                case sensor === "battSOC":
                    label = "Battery Strength";
                    units = "%";
                    break;
                case sensor === "sigStrength":
                    label = "Signal Strength";
                    units = "%";
                    break;
                case sensor === "panelVolts":
                    label = "Solar Panel";
                    units = "V";
                    break;
                case sensor === "leakDetect":
                    label = "Leak Detector";
                    units = "Counts";
                    break;
                case sensor === "pcTrend":
                    label = "Phycocyanin Trend";
                    units = "RFU";
                    break;
                case sensor === "wtTrend":
                    label = "Water Temp. Trend";
                    units = "C";
                    break;
                case sensor === "caTrend":
                    label = "Chlorophyll Trend";
                    units = "RFU";
                    break;
                case sensor === "pcFieldCal":
                    label = "Field Calibrated Phycocyanin";
                    units = "RFU";
                    break;
                case sensor === "caFieldCal":
                    label = "Field Calibrated Chlorophyll";
                    units = "RFU";
                    break;
                default:
                    break;
            }
            return [label, units];
        },
        incrementKeys() {
            this.keyPCStatChart += 1;
            this.keyCAStatChart += 1;
            this.keyTUStatChart += 1;
            this.keyATStatChart += 1;
            this.keyWTStatChart += 1;
            this.keyLIStatChart += 1;
            this.keyCaPc += 1;
            this.keyWaterTemp += 1;
            this.keyBatSig += 1;
            this.keyTurb += 1;
            this.keyLight += 1;
            this.keyWaterTempStatChart += 1;
            this.keyAirTempStatChart += 1;
            this.keyTurbTrend += 1;
            this.keyWaterTempTrend += 1;
            this.keyPlayground += 1;
        },
        resetTrendline() {
            const data = {
                pcProperties: {
                    kl: 0.01, // vertical shift
                    alpha: 0.99, // 0 - 1 lowpass filtering
                    shift: 1, // shift to offset lowpass
                    maxLight: 200, // shift for solar light in lightcomp
                    alphaTemp: 0.99, // 0 - 1 lowpass filtering
                    kTemp: 0.02, // vertical shift
                    stTemp: 25, // temperature at calibration
                    calVal: 710, // value at high calibration
                    useCal: false, // control whether or not calVal is used
                },
                caProperties: {
                    kl: 0.05,
                    alpha: 0.95,
                    shift: 2,
                    maxLight: 400,
                },
                tuProperties: {
                    kl: 0.01,
                    alpha: 0.95,
                    shift: 2,
                    alphaTemp: 0.99,
                    kTemp: 0.02,
                    stTemp: 25,
                    calVal: 50,
                    useCal: false,
                },
                wtProperties: {
                    alpha: 0.95,
                    shift: 2,
                },
                phProperties: {
                    alpha: 0.95,
                    shift: 2,
                },
                doProperties: {
                    alpha: 0.95,
                    shift: 2,
                },
                psProperties: {
                    alpha: 0.95,
                    shift: 2,
                },
            };
            return data;
        },
        resetAlerts() {
            return [
                {
                    devicePropName: "phycocyanin",
                    active: false,
                    low: 0,
                    high: 500,
                },
                {
                    devicePropName: "waterTemp",
                    active: false,
                    low: 0,
                    high: 40,
                },
                {
                    devicePropName: "chlorA",
                    active: false,
                    low: 0,
                    high: 2000,
                },
                {
                    devicePropName: "light",
                    active: false,
                    low: 0,
                    high: 2000,
                },
                {
                    devicePropName: "turbidity",
                    active: false,
                    low: 0,
                    high: 200,
                },
                {
                    devicePropName: "airTemp",
                    active: false,
                    low: 0,
                    high: 40,
                },
                {
                    devicePropName: "do",
                    active: false,
                    low: 0,
                    high: 20,
                },
                {
                    devicePropName: "ph",
                    active: false,
                    low: 0,
                    high: 14
                },
            ];
        },
        blankStatChart() {
            let tmpChart = {};
            for (let i = 0; i < this.sensorGauges.length; i++) {
                {
                    tmpChart[i] = {
                        alertValue: 50,
                        datasets: [
                            {
                                backgroundColor: ["#cfcccc", "#cfcccc"],
                                data: [100, 0],
                                label: "",
                            },
                        ],
                        displayName: "",
                        labels: [],
                        maxValue: 100,
                        minValue: 0,
                        name: "",
                        units: "",
                        value: 50,
                    };
                }
            }
            this.statCharts = tmpChart;
        },
        resetCharts() {
            //this.deviceLat = 360;
            //this.deviceLong = 360;
            //this.inLongLat = false;
            this.deviceInWater = false;
            this.playgroundChart = null;
            this.turbTrendChart = null;
            this.waterTrendChart = null;
            this.algaeTimeReportsChart = null;
            this.waterTempLineChart = null;
            this.turbidityLineChart = null;
            this.batteryAndSingalReportsChart = null;
            this.battPVChart = null;
            this.signalAndPanelTimeReportsChart = null;
            this.ssLiChart = null;
            this.eventTable = [];
        }
    },
};
</script>
  