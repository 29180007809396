<template>
  <div v-if="deviceLat != 0.0" class="card" id="mapBox">
    <GMapMap style="width: 100%;"
     :options="mapOptions" :zoom="12" :center="{
      lat: deviceLat,
      lng: deviceLong,
    }" map-type-id="satellite" @click="onMouseClick">
      <GMapMarker :key="slotName" :position="{
        lat: deviceLat,
        lng: deviceLong,
      }" :title="slotName" />
      <GMapMarker v-if="homeLat && homeLong" :position="{
        lat: homeLat,
        lng: homeLong,
      }" @click="openGpsHome()" :icon="{
      url: 'http://maps.google.com/mapfiles/kml/pushpin/grn-pushpin.png',
      scaledSize: {width: 40, height: 40}}"
        title="Home position" />
      <!-- Icons can be found at http://kml4earth.appspot.com/icons.html -->
      <b-button style="bottom: 5px; left: -12px;"  v-bind:style="{ backgroundColor: homeCursorActivated ? 'green' : 'transparent' }" @click="toggleHome()">Set home📌</b-button>
    </GMapMap>
    <div id="gps" data-toggle="tooltip" data-placement="top" :title="gpsLastUpdate">
      <p style="display: flex; justify-content: center; margin-left: 100px;">
        <span class="text" style="color: white;">
          GPS: {{ deviceLat?.toFixed(5) }},&nbsp;{{ deviceLong?.toFixed(5) }}. Drift={{ getDrift() }} meters
        </span>
        <span style="color: green;">&#10004; GPS locked</span>
      </p>  
    </div>
  </div>
  <div v-else>
    <span class="text"> No Data </span>
  </div>

  <b-modal v-model="gpsHomeModal" title="Select Home Position" @ok="confirmGpsHome()" @cancel="cancelGpsHome()"
    ok-title="Ok">
    <div>
      <b-row>
        <b-col cols="4">
          <p class="util-p">Latitude:</p>
        </b-col>
        <b-col cols="8">
          <b-form-input class="exprt-form" type="number" v-model="homeLat" required>
          </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p class="util-p">Longitude:</p>
        </b-col>
        <b-col cols="8">
          <b-form-input class="exprt-form" type="number" v-model="homeLong" required>
          </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p class="util-p">Drift distance:</p>
        </b-col>
        <b-col cols="8">
          <label class="unitText">{{ getDrift() }} meters</label>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row>
        <b-col cols="4">
          <p class="util-p">Alert distance (meters):</p>
        </b-col>
        <b-col cols="8">
          <b-form-input class="exprt-form" type="number" v-model="gpsHomeDistanceThreshold" required>
          </b-form-input>
        </b-col>
      </b-row>
    </div>
    <b-button class="mt-3" block @click="deleteHome">Delete Home Pin</b-button>
  </b-modal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useSlotStore } from "../stores/slotStore";
const homeLat = ref(0);
const homeLong = ref(0);
const gpsHomeDistanceThreshold = ref(0);
const homeCursorActivated = ref(false);

const slotStore = useSlotStore();
let device = slotStore.currentSlot;
homeLat.value = device.gpsLatHome;
homeLong.value = device.gpsLongHome;
gpsHomeDistanceThreshold.value = device.gpsHomeDistanceThreshold;

const gpsHomeModal = ref(false);

const props = defineProps({
  deviceLat: Number,
  deviceLong: Number,
  gpsLastUpdate: String,
  mapOptions: Object,
  slotName: String
}
)

const onMouseClick = (e) => {
  if (homeCursorActivated.value === false) {
    return;
  }

  const latLng = e.latLng;
  homeLat.value = latLng.lat()?.toFixed(5);
  homeLong.value = latLng.lng()?.toFixed(5);
  gpsHomeModal.value = true;
  homeCursorActivated.value = false;
}

const toggleHome = () => {
  homeCursorActivated.value = !homeCursorActivated.value;
}

const openGpsHome = () => {
  homeLat.value = device.gpsLatHome?.toFixed(5);
  homeLong.value = device.gpsLongHome?.toFixed(5);
  gpsHomeDistanceThreshold.value = device.gpsHomeDistanceThreshold;
  gpsHomeModal.value = true;
}

const confirmGpsHome = async () => {
  const slotId = device._id;
  homeLat.value = parseFloat(homeLat.value);
  homeLong.value = parseFloat(homeLong.value); 
  
  homeCursorActivated.value = false;
  await slotStore.updateSlot(slotId, {
    gpsLatHome: homeLat.value,
    gpsLongHome: homeLong.value,
    gpsHomeDistanceThreshold: parseFloat(gpsHomeDistanceThreshold.value)
  });

  device = slotStore.currentSlot;
  gpsHomeModal.value = false;
}

const cancelGpsHome = async () => {
  homeLat.value = device.gpsLatHome?.toFixed(5);
  homeLong.value = device.gpsLongHome?.toFixed(5);
  gpsHomeDistanceThreshold.value = device.gpsHomeDistanceThreshold;
  gpsHomeModal.value = false;
  homeCursorActivated.value = false;
}

const deleteHome = async() => {
  homeLat.value = 0;
  homeLong.value = 0;
  homeCursorActivated.value = false;
  await confirmGpsHome();
}

function calculateDistance(lat1, lng1, lat2, lng2) {
  var R = 6371; // Radius of the earth in kilometers
  var dLat = toRadians(lat2 - lat1);
  var dLng = toRadians(lng2 - lng1);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
          Math.sin(dLng / 2) * Math.sin(dLng / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function toRadians(deg) {
  return deg * Math.PI / 180;
}

const getDrift = () => {
  if (homeLat.value && homeLong.value) {
    const distance = calculateDistance(props.deviceLat, props.deviceLong, homeLat.value, homeLong.value);
    return parseInt(distance * 1000);
  }
  else {
    return "";
  }
}

</script>
<style scoped>
.vue-map-container {
  height: 92%;
}
</style>